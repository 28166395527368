import React, { ReactElement, useCallback, useState } from 'react'
import styled from 'styled-components'
import { flexColumn } from '../../../../../../style/CommonStyle'
import DispatchDetailInfoItem from './DispatchDetailInfoItem'
import { CDispatchDetailDispatch, CDispatchDetailWithCode } from '../../../../../../model/Dispatch'
import DispatchDetailInfoHeader from './DispatchDetailInfoHeader'
import { OccupancyTypeEnum } from '../../../../../../enum/OccupancyTypeEnum'

type Props = {
  dispatchDetails: CDispatchDetailWithCode[]
  dispatchCode: string
}

export default function DispatchDetailInfo(props: Props): ReactElement {
  const [oneDaySelected, setOnedaySelected] = useState<CDispatchDetailDispatch[] | null>(null);
  const [selectedDispatchDetail, setSelectedDispatchDetail] =
    useState<CDispatchDetailWithCode>(props.dispatchDetails[0])

  const onChangeType = useCallback(
    (dd: CDispatchDetailWithCode) => {
      setSelectedDispatchDetail(dd)
      setOnedaySelected(null)
    },
    [setSelectedDispatchDetail],
  )

  // oneDaySelected이 있으면 해당 데이터를 사용하고, 없으면 selectedDispatchDetail.dispatches를 사용한다.
  const dispatchListData = oneDaySelected
    ? oneDaySelected
    : selectedDispatchDetail.dispatches;

  return (
    <Container>
      <DispatchDetailInfoHeader
        dispatchCode={props.dispatchCode}
        dispatchDetails={props.dispatchDetails}
        setOnedaySelected={setOnedaySelected}
        selectedDispatchDetail={selectedDispatchDetail}
        onChangeType={onChangeType}
      />
      <DispatchDetailListContainer type={selectedDispatchDetail.type}>
        {dispatchListData.map((dispatch, idx) => {
          return (
            <DispatchDetailInfoItem
              key={idx}
              isFirstItem={idx === 0}
              isLastItem={idx === dispatchListData.length - 1}
              dispatch={dispatch}
              type={selectedDispatchDetail.type}
              index={idx}
            />
          )
        })}
      </DispatchDetailListContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  overflow: auto;
  border-radius: 0.8rem;
`

const DispatchDetailListContainer = styled.div<{ type: OccupancyTypeEnum }>`
  ${flexColumn};
  flex: 1;
  background: #ffffff;
`
