import React, { useState } from 'react'
import styled from 'styled-components'
import { CStationDetail } from '../../../model/Station'
import { flexColumn, flexRow } from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import { isEmptyArray } from '../../../util/ValidationUtil'

// image
import BackIcon from '../../../asset/image/back_border.svg'
import ArrowIcon from '../../../asset/image/down_arrow.svg'
import CloseIcon from '../../../asset/image/close_button.svg'
import { useSetRecoilState } from 'recoil'
import { stationModifyAvailableAtom } from '../atom/station-management'

type StationDetailProps = {
  setIsShowStationDetail: React.Dispatch<React.SetStateAction<boolean>>
  showDeleteModal: () => void
  showModifyModal: () => void
  type?: string
  stationDetail: CStationDetail
  onClose?: () => void
  innerHeight?: number
}

function SideBarStationDetail(props: StationDetailProps) {
  const setStationModifyAvailable = useSetRecoilState(stationModifyAvailableAtom);
  const [isShowRoute, setIsShowRoute] = useState<boolean>(false)
  const [isShowDispatches, setIsShowDispatches] = useState<boolean>(false)

  return (
    <StationDetail>
      <StationName>
        {props.type !== 'detail' ? (
          <BackButtonWrapper
            onClick={() => props.setIsShowStationDetail(false)}>
            <BackButton source={BackIcon} />
          </BackButtonWrapper>
        ) : (
          <CloseImgWrapper onClick={props.onClose}>
            <DetailCloseImg source={CloseIcon} />
          </CloseImgWrapper>
        )}
        {props.stationDetail?.station.name}
      </StationName>
      <StationAddress>
        <RoadAddress>{props.stationDetail?.station.roadAddress}</RoadAddress>
        <Address>{props.stationDetail?.station.address}</Address>
      </StationAddress>
      <RouteStudentWrapper>
        {props.stationDetail?.station.type === 'ACADEMY' ? (
          <AcademyStationComment>
            해당 정류장은 학원정류장으로 <strong>다수의 학생</strong>과<br />
            <strong>다수의 노선</strong>에서 이용중 입니다.
          </AcademyStationComment>
        ) : (
          <RouteStudent innerHeight={props.innerHeight}>
            <StudentList>
              <TextWrapper>
                <Title>정류장 이용 학생</Title>
                <SubTitle>{props.stationDetail?.student?.length}명</SubTitle>
              </TextWrapper>
              {isEmptyArray(props.stationDetail?.student) ? (
                <EmptyText>등록된 학생이 없습니다.</EmptyText>
              ) : (
                <Student>
                  {props.stationDetail?.student.map((student, idx) => {
                    return (
                      <Text key={idx}>{`${student.nickName === undefined ? '' : student.nickName
                        } ${student.name} (${student.parentPhone.slice(
                          -4,
                        )})`}</Text>
                    )
                  })}
                </Student>
              )}
            </StudentList>
            <RegisterRoute>
              <RegisterRouteHeader>
                <TextWrapper>
                  <Title>등록 노선</Title>
                  <SubTitle>{props.stationDetail?.courses?.length}개</SubTitle>
                </TextWrapper>
                <ArrowButton
                  onClick={() => setIsShowRoute(!isShowRoute)}
                  isShowRoute={isShowRoute}>
                  <ArrowImg source={ArrowIcon} />
                </ArrowButton>
              </RegisterRouteHeader>
              {isEmptyArray(props.stationDetail?.courses) && isShowRoute ? (
                <EmptyText>등록된 노선이 없습니다.</EmptyText>
              ) : (
                ''
              )}
              {!isEmptyArray(props.stationDetail?.courses) && isShowRoute
                ? props.stationDetail?.courses?.map((course, idx) => {
                  return (
                    <RouteCard key={idx}>
                      <RouteNameWrapper>
                        <RouteLabel type={course.type}>
                          {course.type === 'OUTBOUND'
                            ? '하원'
                            : course.type === 'INBOUND'
                              ? '등원'
                              : '순환'}
                        </RouteLabel>
                        <RouteName>{course.name}</RouteName>
                      </RouteNameWrapper>
                      <RouteCourse>
                        {course?.stations.map((s, i) => {
                          return (
                            <Station
                              key={s.id}
                              highlight={
                                props.stationDetail.station.name === s.name
                              }>
                              {s.name}
                              {course?.stations.length - 1 === i ? '' : ' - '}
                            </Station>
                          )
                        })}
                      </RouteCourse>
                    </RouteCard>
                  )
                })
                : ''}
            </RegisterRoute>
            <RegisterDispatch>
              <RegisterRouteHeader>
                <TextWrapper>
                  <Title>등록 배차</Title>
                  <SubTitle>
                    {props.stationDetail?.dispatches?.length}개
                  </SubTitle>
                </TextWrapper>
                <ArrowButton
                  onClick={() => setIsShowDispatches(!isShowDispatches)}
                  isShowDispatches={isShowDispatches}>
                  <ArrowImg source={ArrowIcon} />
                </ArrowButton>
              </RegisterRouteHeader>
              {isEmptyArray(props.stationDetail?.dispatches) &&
                isShowDispatches ? (
                <EmptyText>등록된 배차가 없습니다.</EmptyText>
              ) : (
                ''
              )}
              {!isEmptyArray(props.stationDetail?.dispatches) &&
                isShowDispatches
                ? props.stationDetail?.dispatches?.map((d, i) => {
                  return (
                    <RouteCard key={i}>
                      <DispatchNameWrapper>
                        <DispatchName>{d.busName}</DispatchName>
                        <DispatchName>{`출발 ${d.startTime}  - 도착 ${d.endTime}`}</DispatchName>
                      </DispatchNameWrapper>
                      <DispatchContent>
                        <RouteLabel type={d.type}>
                          {d.type === 'OUTBOUND'
                            ? '하원'
                            : d.type === 'INBOUND'
                              ? '등원'
                              : '순환'}
                        </RouteLabel>
                        <DispatchWeekDay>
                          {d.weekday.exposure}
                        </DispatchWeekDay>
                      </DispatchContent>
                    </RouteCard>
                  )
                })
                : ''}
            </RegisterDispatch>
          </RouteStudent>
        )}
      </RouteStudentWrapper>
      <StationButtonWrapper>
        <DeleteStationButton onClick={props.showDeleteModal}>
          정류장 삭제
        </DeleteStationButton>
        <ModifyStationButton
          onClick={() => {
            props.showModifyModal()
            setStationModifyAvailable(props.stationDetail.canUpdateStationType)
          }}>
          정류장 수정
        </ModifyStationButton>
      </StationButtonWrapper>
    </StationDetail>
  )
}

export default SideBarStationDetail

const StationDetail = styled.div`
  padding: 1.6rem 0;
  max-width: 26rem;
  height: 100%;

  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
`

const BackButtonWrapper = styled.div``

const StationName = styled.div`
  ${flexRow};
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 800;
  padding: 0 1.6rem;
  margin-bottom: 0.4rem;
`

const CloseImgWrapper = styled.div``

const DetailCloseImg = styled(SVGImage)`
  position: absolute;
  top: 2.2rem;
  right: 1.5rem;
  width: 1.8rem;
  height: 1.8rem;
`

const RouteStudentWrapper = styled.div`
  height: calc(100% - 10rem);
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

const StationAddress = styled.div`
  row-gap: 0.2rem;
`

const RegisterRoute = styled.div`
  padding: 1.6rem;
  border-bottom: 0.1rem solid #ebebeb;
`

const RegisterDispatch = styled(RegisterRoute)`
  border-bottom: none;
`

const RegisterRouteHeader = styled.div`
  ${flexRow};
  justify-content: space-between;
  align-items: center;
`

const RouteCard = styled.div`
  border-radius: 0.8rem;
  border: 0.1rem solid #ebebeb;
  background: #f5f5f5;
  margin-top: 0.6rem;
`

const RouteNameWrapper = styled.div`
  ${flexRow};
  padding: 0.8rem;
  border-radius: 0.8rem 0.8rem 0 0;
  column-gap: 0.4rem;
  border-bottom: 0.1rem solid #ebebeb;
  background: #fff;
`

const DispatchNameWrapper = styled.div`
  ${flexColumn};
  background: #fff;
  padding: 0.8rem;
  border-radius: 0.8rem 0.8rem 0 0;
`

const DispatchContent = styled.div`
  ${flexRow};
  justify-content: space-between;
  padding: 0.4rem 0.8rem;
  align-items: center;
`

const DispatchWeekDay = styled.div`
  font-size: 1.1rem;
  line-height: 150%;
  font-weight: 500;
`

type LabelProps = {
  type?: string
}
const RouteLabel = styled.div<LabelProps>`
  min-width: 2.8rem;
  min-height: 1.4rem;
  max-height: 1.4rem;
  padding: 0.1rem 0.3rem;
  margin-top: 0.2rem;
  background: ${props =>
    props.type === 'INBOUND'
      ? '#009A17'
      : props.type === 'OUTBOUND'
        ? '#6092E1'
        : '#FFD100'};
  border: none;
  border-radius: 1.2rem;
  color: ${props =>
    props.type === 'INBOUND' || props.type === 'OUTBOUND' ? '#fff' : '#332a00'};
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 700;
  letter-spacing: -0.024rem;
`

const RouteName = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
`

const DispatchName = styled(RouteName)`
  text-overflow: ellipsis;
`

const RouteCourse = styled.div`
  ${flexRow};
  display: block;
  padding: 0.4rem 0.8rem;
  background: #f5f5f5;
`

type StationProps = {
  highlight: boolean
}

const Station = styled.span<StationProps>`
  font-size: 1.1rem;
  line-height: 150%;
  font-weight: ${props => (props.highlight ? 700 : 400)};
  color: #666;
`

const StudentList = styled.div`
  padding: 1.6rem;
  border-bottom: 0.1rem solid #ebebeb;
`

const Student = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.2rem;
  row-gap: 0.2rem;
  margin-top: 0.6rem;
`

const Text = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TextWrapper = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
`

type ArrowProps = {
  isShowRoute?: boolean
  isShowDispatches?: boolean
}

const ArrowButton = styled.div<ArrowProps>`
  transform: ${props =>
    props.isShowRoute || props.isShowDispatches ? 'rotate(180deg)' : ''};
  transition: transform 0.5s ease-in-out;
`

const ArrowImg = styled(SVGImage)`
  width: 2rem;
  height: 2rem;
`

const Title = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 700;
`

const EmptyText = styled.div`
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.8rem;
  color: #999;
  margin-top: 0.6rem;
`

const SubTitle = styled.div`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4rem;
  color: #666;
`

const RoadAddress = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  padding: 0 1.6rem;
`

const Address = styled(RoadAddress)`
  color: #666;
  padding-bottom: 1.6rem;
  border-bottom: 0.1rem solid #ebebeb;
`

const BackButton = styled(SVGImage)`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;
  margin-top: 0.3rem;
`

const StationButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1.6rem 0;
  background: #fff;
  z-index: 9;
  border-radius: 0 0 1.6rem 0;
  ${flexRow};
  justify-content: center;
  align-items: center;
  column-gap: 0.8rem;
  width: 26rem;
  border-top: 0.1rem solid #ebebeb;
  border-left: 0.1rem solid #ebebeb;
`

const DeleteStationButton = styled.button`
  border: none;
  border-radius: 3.4rem;
  background: #332a00;
  padding: 0.4rem 1.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
  color: #fff1b3;
`

const ModifyStationButton = styled(DeleteStationButton)`
  border: 0.1rem solid #2767ce;
  color: #2767ce;
  background: #fff;
`

type RouteStudentProps = {
  innerHeight: number
}

const RouteStudent = styled.div<RouteStudentProps>`
  overflow-y: scroll;
  height: ${props => props.innerHeight - 275};

  ::-webkit-scrollbar {
    display: none;
  }
`

const AcademyStationComment = styled.div`
  text-align: center;
  color: #999999;
  padding: 16px;
  font-size: 11px;
  line-height: 18px;
  font-weight: 300;

  strong {
    font-size: 11px;
  }
`
