import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import { flexRow, PrimaryText, WarningText } from '../../../style/CommonStyle'
import { Optional } from '../../../type/Common'
import CloseIcon from '../../../asset/image/close_yellow.svg'
import SVGImage from '../../common/SVGImage'
import Picker, { PickerRef, toPickerOptions } from '../../input/Picker'
import StationSideBarCourseSetting, {
  StationInfo,
  StationSideBarCourseSettingRef,
} from './StationSideBarCourseSetting'
import { isNil } from '../../../util/ValidationUtil'
import { OperationTypeEnum } from '../../../enum/OperationTypeEnum'
import useSecureRef from '../../../hook/useSecureRef'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { academyIDState } from '../../../recoil/Atom'
import { getCourses, getSearchCourseDetail, postCourses, putCourses } from '../../../service/station/Station'
import { Coordinate } from '../kakaoMap/KakaoMap'
import { CCourseDetail, CDefaultCourse, CStation } from '../../../model/Station'
import PlusIcon from '../../../asset/image/plus_icon_yellow.svg'
import { CourseProps } from './SearchList'
import { toastAtom } from '../atom/station-management'

type AddCourseProps = {
  handleCloseAddCourseButton: () => void
  fixedCenterLatLng: (center: { lat: number; lng: number }) => void
  onChangeCoordinate: (stations: Coordinate[]) => void
  modifyType: number // 배차보유 숫자: 0일경우 수정, 1개 이상 보유시 복제
  courseDetail?: CCourseDetail
  getDefaultCourseList(): void
  handleSearchButton(): void
  setCloseModifyCourseStatus?: React.Dispatch<React.SetStateAction<boolean>>
  addNewCourse?: boolean
  setAddNewCourse?: React.Dispatch<React.SetStateAction<boolean>>
  setOperationType?: React.Dispatch<React.SetStateAction<Optional<string>>>
  setDisableCirculation: React.Dispatch<React.SetStateAction<boolean>>
  setContainStations: React.Dispatch<React.SetStateAction<StationInfo[]>>
  selectedStation: CStation
  setSelectedStation: React.Dispatch<React.SetStateAction<Optional<CStation>>>
  setIsOpenModifyCourse: React.Dispatch<React.SetStateAction<boolean>>
  addNewStation: boolean
  setAddNewStation: React.Dispatch<React.SetStateAction<boolean>>
  centerStation: CStation[]
  setConfirmModifyCourseModal: React.Dispatch<React.SetStateAction<boolean>>
  setConfirmAddCourseModal: React.Dispatch<React.SetStateAction<boolean>>
  setAddCourse: React.Dispatch<React.SetStateAction<boolean>>
  dispatchPeriodId: number; // 배차기간 ID, 위에서 내려옴
  setCourseDetail: React.Dispatch<React.SetStateAction<CCourseDetail>> // 복제 후 복제된 노선을 선택된 상태로 하기 위한 Props
  selectedYear: number // 년도
  setDetailCard: React.Dispatch<React.SetStateAction<boolean>>  // try 1
  setCard: React.Dispatch<React.SetStateAction<CourseProps | CDefaultCourse>>  // try 2
}

export interface StationSideBarAddCourseRef {
  onSubmit: () => void
}

const StationSideBarAddCourse: React.ForwardRefRenderFunction<
  StationSideBarAddCourseRef,
  AddCourseProps
> = (props, ref) => {
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const setToast = useSetRecoilState(toastAtom)
  const courseSettingRef = useSecureRef<StationSideBarCourseSettingRef>(
    '[StationSideBarAddCourse.tsx] courseSettingRef',
  )
  const pickerRef = useSecureRef<PickerRef<OperationTypeEnum>>(
    '[StationSideBarAddCourse.tsx] pickerRef',
  )

  const [operationType, setOperationType] = useState<
    Optional<OperationTypeEnum>
  >(props.courseDetail?.course.type)
  const [courseName, setCourseName] = useState<Optional<string>>(
    props.courseDetail?.course.name,
  )
  const [isSufficient, setIsSufficient] = useState<boolean>(false)
  const [error, setError] = useState<Optional<string>>(null)
  const [stations, setStations] = useState<CStation[]>([])

  const operationTypePickerOptions = useMemo(() => {
    return toPickerOptions(
      OperationTypeEnum.ALL,
      v => v.exposure,
      v => v.exposure,
    )
  }, [])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const handleChangeKeyword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCourseName(e.target.value)
    },
    [courseName],
  )

  const handleSelectType = useCallback(
    (v: React.SetStateAction<OperationTypeEnum>) => {
      setOperationType(v)
    },
    [],
  )

  useEffect(() => {
    if (
      operationType === OperationTypeEnum.CIRCULATION &&
      stations.length === 1
    ) {
      props.setDisableCirculation(true)
    }
  }, [operationType, stations])

  type Station = {
    stationId: number | null
    spendTime: number | null
    lat: number | null
    lng: number | null
    name: string | null
  }

  const isStationComplete = (station: Station | null | undefined) =>
    station !== null &&
    station !== undefined &&
    Object.values(station).every(value => value !== null && value !== undefined)

  const checkStations = (stations: (Station | null | undefined)[]) => {
    for (const station of stations) {
      if (!isStationComplete(station)) {
        console.log(`정류소 정보가 불완전합니다.`)
        return false
      }
    }
    console.log('모든 정류소 정보가 완전합니다.')
    return true
  }

  const onChangeStationInfos = useCallback(
    (sis: Station[]) => {
      console.log(sis)
      const sufficient = checkStations(sis)
      setIsSufficient(sufficient)
    },
    [setIsSufficient],
  )

  const onSubmit = useCallback(async () => {
    if (!isSufficient) {
      alert('필수 항목을 입력해주세요!');
      return;
    }

    const stationInfos = courseSettingRef.current().getStationInfos();
    const commonData = {
      academyID: selectedAcademyID,
      dispatchType: operationType.value,
      name: courseName,
      route: stationInfos,
    };

    const handleSuccess = () => {
      props.getDefaultCourseList();
      setToast('course-add')
      props.setAddCourse(false);
      props.setAddNewCourse(false);
      props.handleCloseAddCourseButton();
    };

    const handleError = (error: any) => {
      setError(error.response?.data.data);
    };

    try {
      // case 1. 노선 추가인 경우
      if (props.addNewCourse) {
        await postCourses(commonData);
        handleSuccess();
        props.handleSearchButton();
        // case 2. 복제인 경우
      } else if (props.modifyType > 0) {
        // 복제한 노선 생성
        const resp = await postCourses(commonData);
        // 복제한 노선의 ID
        const copiedCourseID = resp.data.id;
        // 복제한 노선의 상세 정보 가져오기
        const copiedCourseDetail = await getSearchCourseDetail(selectedAcademyID, copiedCourseID, props.dispatchPeriodId, props.selectedYear);
        // 만들 때 사용했던 이름으로 노선 찾기
        const searchedCourse = await getCourses({
          academyID: selectedAcademyID,
          year: props.selectedYear,
          page: 0,
          size: 50,
          keyword: courseName,
          dispatchPeriodId: props.dispatchPeriodId,
        })

        // 복제한 노선을 전체 리스트에서 찾기
        const findCourse = searchedCourse ? searchedCourse.courses.find((c: CDefaultCourse) => c.id === copiedCourseID) : null;
        
        //있다면
        if(findCourse) { 
          props.setCourseDetail(copiedCourseDetail);
          props.setDetailCard(true);
          props.setCard(findCourse);
        // 없다면 노선 생성과 같은 액션
        } else {
          props.handleSearchButton();
        }

        // 성공시의 액션
        handleSuccess();

        // 추가적인 액션 수행
        // 예: 특정 상태 업데이트, 추가 API 호출 등
        // case 3. 수정인 경우
      } else {
        const data = {
          ...commonData,
          courseID: String(props?.courseDetail?.course.id),
        };

        await putCourses(data);
        props.getDefaultCourseList();
        props.handleSearchButton();
        props.setCloseModifyCourseStatus(true);
        setToast('course-modify')
      }
    } catch (error) {
      handleError(error);
    }

    props.setConfirmModifyCourseModal(false);
  }, [
    operationType,
    isSufficient,
    courseName,
    props.setCloseModifyCourseStatus,
  ]);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit,
    }),
    [onSubmit],
  )

  const openConfirmModal = useCallback(() => {
    props.setConfirmModifyCourseModal(true)
  }, [props.setConfirmModifyCourseModal])

  const handleCancelButton = useCallback(() => {
    //등록 취소
    if (props.addNewCourse) {
      props.setAddNewCourse(false)
      props.setOperationType(null)
      props.handleCloseAddCourseButton()
      return
    }
    if (!props.addNewCourse && props.modifyType > 0) {
      //복제 취소
      props.handleCloseAddCourseButton()
      return
    }
    if (!props.addNewCourse && props.modifyType === 0) {
      //수정 취소
      props.handleCloseAddCourseButton()
      return
    }
  }, [])

  const errorMessage = error?.split(':')

  useEffect(() => {
    setCourseName(props.courseDetail?.course.name)
  }, [props.courseDetail])

  useEffect(() => {
    if (isNil(props.courseDetail)) {
      pickerRef.current().setIdx(null)
      return
    }

    if (props.courseDetail.course.type === OperationTypeEnum.INBOUND) {
      pickerRef.current().setIdx(0)
      return
    }

    if (props.courseDetail.course.type === OperationTypeEnum.OUTBOUND) {
      pickerRef.current().setIdx(1)
      return
    }

    pickerRef.current().setIdx(2)
  }, [props.courseDetail])

  useEffect(() => {
    setOperationType(props.courseDetail?.course.type)
  }, [props.courseDetail])

  useEffect(() => {
    setError(null)
  }, [courseName])

  useEffect(() => {
    if (!isNil(operationType && props.setOperationType)) {
      props.setOperationType(operationType.exposure)
    }
  }, [operationType])

  return (
    <AddCourseContainer>
      <Title>
        노선{' '}
        {props.addNewCourse
          ? '추가'
          : props.modifyType === 0
            ? '수정'
            : props.modifyType > 0
              ? '복제'
              : ''}
      </Title>
      <CategoryWrapper>
        <Category>
          <PrimaryText>등하원 구분</PrimaryText>
          <WarningText>*</WarningText>
        </Category>

        <Picker
          ref={pickerRef.ref}
          options={operationTypePickerOptions}
          placeholder={'노선 유형을 선택해 주세요.'}
          minWidth={228}
          height={34}
          onChange={handleSelectType}
          defaultIdx={null}
        />
      </CategoryWrapper>
      <CategoryWrapper>
        <Category>
          <PrimaryText>노선명</PrimaryText>
          <WarningText>*</WarningText>
        </Category>
        <InputWrapper>
          <Input
            placeholder="노선명을 입력해주세요."
            onKeyDown={handleKeyDown}
            onChange={handleChangeKeyword}
            value={courseName}
            errorMessage={errorMessage}
          />
          {!isNil(errorMessage) && <ErrorText>{errorMessage[1]}</ErrorText>}
          {courseName !== '' && (
            <ResetCourseNameButton onClick={() => setCourseName('')}>
              <CloseImg source={CloseIcon} />
            </ResetCourseNameButton>
          )}
        </InputWrapper>
      </CategoryWrapper>
      <StationSetting>
        <Category>
          <PrimaryText>정류장 추가 및 정렬</PrimaryText>
          <WarningText>*</WarningText>
          {operationType && (
            <AddStationButton
              onClick={() => {
                courseSettingRef.current().addStationInfo()
              }}>
              <PlusImg source={PlusIcon} />
              정류장 추가
            </AddStationButton>
          )}
        </Category>
        {!isNil(operationType) &&
          !(
            operationType === OperationTypeEnum.CIRCULATION &&
            stations.length === 1
          ) && (
            <StationSideBarCourseSetting
              ref={courseSettingRef.ref}
              operationType={operationType}
              setFixedCenterLatLng={props.fixedCenterLatLng}
              onChangeStationInfos={onChangeStationInfos}
              onChangeCoordinate={props.onChangeCoordinate}
              isSufficient={isSufficient}
              modifyType={props.modifyType}
              courseDetail={props.courseDetail}
              addNewCourse={props.addNewCourse}
              setStationList={setStations}
              selectedStation={props.selectedStation}
              setSelectedStation={props.setSelectedStation}
              addNewStation={props.addNewStation}
              setAddNewStation={props.setAddNewStation}
              centerStation={props.centerStation}
            />
          )}
      </StationSetting>

      <ButtonWrapper>
        <CancelButton onClick={handleCancelButton}>취소</CancelButton>
        <RegisterButton onClick={openConfirmModal} status={isSufficient}>
          {props.addNewCourse ? '등록' : '저장'}
        </RegisterButton>
      </ButtonWrapper>
    </AddCourseContainer>
  )
}

export default forwardRef<StationSideBarAddCourseRef, AddCourseProps>(
  StationSideBarAddCourse,
)

const AddCourseContainer = styled.div`
  background: #fff;
  height: 100%;
  width: 26rem;
  border-radius: 0 1.6rem 1.6rem 0;
  position: relative;
  z-index: 999;
`

const Title = styled.div`
  padding: 0.6rem 1.6rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 700;
  background: #332a00;
  color: #ffd100;
  border-radius: 0 1.6rem 0 0;
`

const CategoryWrapper = styled.div`
  padding: 1.6rem;
  border-bottom: 0.1rem solid #ebebeb;
`

const Category = styled.div`
  ${flexRow};
  column-gap: 0.3rem;
  margin-bottom: 0.6rem;
  position: relative;
`

const InputWrapper = styled.div`
  position: relative;
`

type InputProps = {
  errorMessage?: string[]
}

const Input = styled.input<InputProps>`
  border: ${props =>
    props.errorMessage ? '0.1rem solid #ED766E' : '0.1rem solid #ebebeb'};
  border-radius: 0.6rem;
  background: ${props => (props.errorMessage ? '#FCE6E4' : '#fff')};
  color: ${props => (props.errorMessage ? '#DA291C' : '#332a00')};
  height: 3.5rem;
  padding: 0.6rem 0.8rem;
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;

  ::placeholder {
    color: #000;
    font-weight: 400;
  }

  :focus-within {
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
    border: 0.1rem solid #fff1b3;
    background: #fffbe5;
    font-weight: 400;
  }
`

const ResetCourseNameButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 0.6rem;
`

const CloseImg = styled(SVGImage)`
  width: 1.6rem;
  height: 1.6rem;
`

const StationSetting = styled.div`
  padding: 1.6rem;
  height: calc(100% - 27.5rem);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 26rem;
  border-top: 0.1rem solid #ebebeb;
  height: 5.8rem;
  background: #fff;
  padding: 1.6rem 0;
  ${flexRow};
  column-gap: 0.8rem;
  justify-content: center;
  border-radius: 0 0 1.6rem 0;
`

const CancelButton = styled.button`
  width: 6.7rem;
  height: 2.6rem;
  display: flex;
  justify-content: center;
  text-align: center;
  border: none;
  border-radius: 3.4rem;
  background: #ebebeb;
  padding: 0.4rem 1.2rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
`

type ButtonProps = {
  status: boolean
}

const RegisterButton = styled(CancelButton) <ButtonProps>`
  background: ${props => (props.status ? '#FFD100' : '#fff1b3')};
  color: ${props => (props.status ? '#332A00' : '#cccccc')};
`

const ErrorText = styled.div`
  color: #e74d41;
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 300;
  margin-top: 0.6rem;
`

const AddStationButton = styled.button`
  ${flexRow};
  border: none;
  border-radius: 1.6rem;
  background: #332a00;
  color: #ffd100;
  height: 2.2rem;
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 500;
  padding: 0.4rem 0.8rem;
  position: absolute;
  right: 0px;
  top: -1px;
`
const PlusImg = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`
