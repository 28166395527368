import { OperationTypeEnum } from '../enum/OperationTypeEnum'
import { WeekdayEnum } from '../enum/WeekdayEnum'
import { CPaging } from './Paging'

export class CStation {
  private constructor(
    readonly type: string,
    readonly id: number,
    readonly name: string,
    readonly roadAddress: string,
    readonly address: string,
    readonly lat: number,
    readonly lng: number,
    readonly spendTime?: number,
  ) { }

  static create(json: any) {
    return new CStation(
      json.type,
      json.id,
      json.name,
      json.roadAddress,
      json.address,
      json.lat,
      json.lng,
      json.spendTime,
    )
  }
}

// NOT_USED(0, "미사용 정류장"),
// USED_IN_BOTH(1, "배차와 노선 모두 사용 중"),
// ONLY_IN_COURSE(2, "노선에만 연결"),
// ONLY_IN_DISPATCH(3, "배차에만 연결(데이터 오류 가능)");

export const cStationTypes = [
  'NOT_USED', // 미사용 정류장
  'USED_IN_BOTH', // 배차와 노선 모두 사용 중
  'ONLY_IN_COURSE', // 노선에만 연결
  'ONLY_IN_DISPATCH' // 배차에만 연결(데이터 오류 가능)
] as const

export type CStationType = typeof cStationTypes[number]
export const CStationTypeLabels = {
  NOT_USED: '미사용',
  USED_IN_BOTH: '정상 등록',
  ONLY_IN_COURSE: '배차 미등록',
  ONLY_IN_DISPATCH: '노선 미등록',
}


export interface CStationWithType {
  dybStationListDTO: CStation;
  stationFilterType: CStationType;
}

export class CStationVector {
  private constructor(
    readonly stations: CStation[],
    readonly paging: CPaging,
  ) { }

  static create(json: any) {
    return new CStationVector(
      json.stations.map((s: any) => CStation.create(s)),
      CPaging.create(json.paging),
    )
  }
}

export class CStationAddress {
  private constructor(
    readonly address: string,
    readonly lat: number,
    readonly lng: number,
    readonly id: number,
    readonly name: string,
  ) { }

  static create(json: any) {
    return new CStationAddress(
      json.address,
      json.lat,
      json.lng,
      json.stationId,
      json.stationName,
    )
  }
}

export class CAcademyData {
  private constructor(
    readonly academyId: number,
    readonly dybId: string,
    readonly lat: number,
    readonly lng: number,
    readonly name: string,
  ) { }

  static create(json: any) {
    return new CAcademyData(
      json.academyId,
      json.dybId,
      json.lat,
      json.lng,
      json.name,
    )
  }
}

export class CCourseStation {
  private constructor(
    readonly id: number,
    readonly name: string,
    readonly lat: number,
    readonly lng: number,
  ) { }
  static create(json: any) {
    return new CCourseStation(json.id, json.name, json.lat, json.lng)
  }
}
export class CCourse {
  private constructor(
    readonly type: string,
    readonly name: string,
    readonly sortStationName: string,
    readonly stations: CCourseStation[],
  ) { }

  static create(json: any) {
    return new CCourse(json.type, json.name, json.sortStationName, json.course)
  }
}

export class CStationStudent {
  private constructor(
    readonly name: string,
    readonly nickName: string,
    readonly parentPhone: string,
  ) { }

  static create(json: any) {
    return new CStationStudent(json.name, json.nickName, json.parentPhone)
  }
}

export class CSearchList {
  private constructor(
    readonly address: string,
    readonly id: number,
    readonly lat: number,
    readonly lng: number,
    readonly name: string,
    readonly roadAddress: string,
    readonly type: string,
  ) { }

  static create(json: any) {
    return new CSearchList(
      json.address,
      json.id,
      json.lat,
      json.lng,
      json.name,
      json.roadAddress,
      json.type,
    )
  }
}

export class CSearchPlace {
  private constructor(
    readonly name: string,
    readonly address: string,
    readonly roadAddress: string,
    readonly lat: number,
    readonly lng: number,
  ) { }

  static create(json: any) {
    return new CSearchPlace(
      json.address,
      json.lat,
      json.lng,
      json.name,
      json.roadAddress,
    )
  }
}

export class CStationDetailAddress {
  private constructor(
    readonly name: string,
    readonly address: string,
    readonly roadAddress: string,
    readonly lat: number,
    readonly lng: number,
    readonly type: string,
  ) { }

  static create(json: any) {
    return new CStationDetailAddress(
      json.address,
      json.roadAddress,
      json.name,
      json.lat,
      json.lng,
      json.type,
    )
  }
}

export class CStationDetailCourse {
  private constructor(
    readonly name: string,
    readonly sortedStationName: string,
    readonly stations: { id: number; name: string; lat: number; lng: number }[],
    readonly type: string,
  ) { }

  static create(json: any) {
    return new CStationDetailCourse(
      json.name,
      json.sortedStationName,
      json.stations,
      json.type,
    )
  }
}

export class CStationDetailDispatch {
  private constructor(
    readonly busName: string,
    readonly startTime: string,
    readonly endTime: string,
    readonly type: string,
    readonly weekday: WeekdayEnum,
  ) { }

  static create(json: any) {
    return new CStationDetailDispatch(
      json.busName,
      json.startTime,
      json.endTime,
      json.type,
      WeekdayEnum.parseValueToWeekday(json.weekday),
    )
  }
}

export class CStationDetail {
  private constructor(
    readonly canUpdateStationType: boolean,
    readonly courses: CStationDetailCourse[],
    readonly dispatches: CStationDetailDispatch[],
    readonly station: CStationDetailAddress,
    readonly student: CStationStudent[],
  ) { }

  static create(json: any) {
    return new CStationDetail(
      json.canUpdateStationType,
      json.courses.map((el: any) => CStationDetailCourse.create(el)),
      json.dispatches.map((el: any) => CStationDetailDispatch.create(el)),
      json.station,
      json.student.map((el: any) => CStationStudent.create(el)),
    )
  }
}

export class CCourseDispatches {
  private constructor(
    readonly busName: string,
    readonly endTime: string,
    readonly startTime: string,
    readonly type: OperationTypeEnum,
    readonly weekday: WeekdayEnum,
    readonly startDate: string,
    readonly endDate: string,
  ) { }

  static create(json: any) {
    return new CCourseDispatches(
      json.busName,
      json.endTime,
      json.startTime,
      OperationTypeEnum.parseValue(json.type),
      WeekdayEnum.parseValueToWeekday(json.weekday),
      json.startDate,
      json.endDate,
    )
  }
}

export class CCourseStations {
  private constructor(
    readonly id: number,
    readonly sort: number,
    readonly name: string,
    readonly lat: number,
    readonly lng: number,
    readonly address: string,
    readonly roadAddress: string,
    readonly spendTime: number,
    readonly type: string,
  ) { }

  static create(json: any) {
    return new CCourseStations(
      json.id,
      json.sort,
      json.name,
      json.lat,
      json.lng,
      json.address,
      json.roadAddress,
      json.spendTime,
      json.type,
    )
  }
}

export class CCourseWithDuration {
  private constructor(
    readonly id: number,
    readonly name: string,
    readonly route: string,
    readonly totalDuration: number,
    readonly stations: CCourseStations[],
    readonly type: OperationTypeEnum,
  ) { }

  static create(json: any) {
    return new CCourseWithDuration(
      json.id,
      json.name,
      json.route,
      json.totalDuration,
      json.stations.map((el: any) => CCourseStations.create(el)),
      OperationTypeEnum.parseValue(json.type),
    )
  }
}

export class CCourseDetail {
  private constructor(
    readonly course: CCourseWithDuration,
    readonly dispatches: CCourseDispatches[],
  ) { }

  static create(json: any) {
    return new CCourseDetail(
      CCourseWithDuration.create(json.course),
      json.dispatches.map((el: any) => CCourseDispatches.create(el)),
    )
  }
}

export class CAddressVector {
  private constructor(
    readonly name: string,
    readonly lat: number,
    readonly lng: number,
  ) { }

  static create(json: any): CAddressVector {
    return new CAddressVector(json.name, json.lat, json.lng)
  }
}

export class CDefaultCourse {
  private constructor(
    readonly id: number,
    readonly name: string,
    readonly dispatchType: string,
    readonly totalOperationTime: number,
    readonly dispatchCount: number,
    readonly stationCount: number,
  ) { }

  static create(json: any): CDefaultCourse {
    return new CDefaultCourse(
      json.id,
      json.name,
      json.dispatchType,
      json.totalOperationTime,
      json.dispatchCount,
      json.stationCount,
    )
  }
}

export class CLatLng {
  private constructor(
    readonly lat: number,
    readonly lng: number,
  ) { }

  static create(json: any): CLatLng {
    {
      return new CLatLng(json.lat, json.lng)
    }
  }
}
