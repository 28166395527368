import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router'
import ScreenContainer from '../../component/layout/ScreenContainer'
import useSecureRef from '../../hook/useSecureRef'
import { ModalContainerRef } from '../../component/modal/ModalContainer'
import HalfPopup from '../../component/common/HalfPopUp'
import DeleteStation from '../../component/stationManagement/modalContents/DeleteStation'
import { Optional } from '../../type/Common'
import { CStation } from '../../model/Station'
import ModifyStation from '../../component/stationManagement/modalContents/ModifyStation'
import KakaoMap from '../../component/stationManagement/kakaoMap/KakaoMap'
import { deleteStation, putStation } from '../../service/station/Station'
import { useRecoilState, useRecoilValue } from 'recoil'
import { academyIDState } from '../../recoil/Atom'
import FailedDeleteStation from '../../component/stationManagement/modalContents/FailedDeleteStation'
import DeleteConfirm from '../../component/stationManagement/modalContents/DeleteConfirm'
import ConfirmModifyStation from '../../component/stationManagement/modalContents/ConfirmModifyStation'
import Toast from '../../component/toast/Toast'
import CourseDelete from '../../component/stationManagement/modalContents/CourseDelete'
import CancelModifyCourse from '../../component/stationManagement/modalContents/CancelModifyCourse'
import DisableCirculation from '../../component/stationManagement/modalContents/DisableCircultaion'
import ConfirmModifyCourse from '../../component/stationManagement/modalContents/ConfirmModifyCourse'
import StationSideBarAddCourse, {
  StationSideBarAddCourseRef,
} from '../../component/stationManagement/stationSideBar/StationSideBarAddCourse'
import CancelCopyCourse from '../../component/stationManagement/modalContents/CancelCopyCourse'
import ConfirmAddCourse from '../../component/stationManagement/modalContents/ConfirmAddCourse'
import { useStationManagementModals } from '../../component/stationManagement/hooks/useStationManagementModals'
import { stationAtom, toastAtom } from '../../component/stationManagement/atom/station-management'

type StationManagementProps = {}

export type StationManagementRef = {}

export type ToastType = 'station-add' | 'course-delete' | 'course-modify' | 'course-add'

function StationManagementBase(
  _: StationManagementProps,
  __: ForwardedRef<StationManagementRef>,
) {

  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const [toast, setToast] = useRecoilState(toastAtom)
  const station = useRecoilValue(stationAtom);

  const {
    delete: deleteRef,
    deleteConfirm: deleteConfirmRef,
    modify: modifyRef,
    modifyConfirm: modifyConfirmRef,
    failedDelete: failedDeleteRef,
    courseDelete: courseDeleteRef,
    cancelCourseModify: cancelCourseModifyRef,
    cancelCourseCopy: cancelCourseCopyRef,
    confirmCourseModify: confirmCourseModifyRef,
    confirmAddCourse: confirmAddCourseRef,
    disableCirculation: disableCirculationRef,
    hideAll,
    showModalByKey,
    hideModalByKey
  } = useStationManagementModals()

  const addCourseRef = useRef<StationSideBarAddCourseRef>(null)

  const [refresh, setRefresh] = useState<boolean>(false)
  const [stationDeleteRefresh, setStationDeleteRefresh] =
    useState<boolean>(false)
  //모달

  const [deleteStatus, setDeleteStatus] = useState<boolean>(false)
  const [modifyCourseStatus, setModifyCourseStatus] = useState<boolean>(false)
  const [copyCourseStatus, setCopyCourseStatus] = useState<boolean>(false)
  const [disableCirculation, setDisableCirculation] = useState<boolean>(false)
  const [confirmModifyCourseModal, setConfirmModifyCourseModal] = useState<boolean>(false)
  const [confirmAddCourseModal, setConfirmAddCourseModal] = useState<boolean>(false)
  // 카카오맵으로 넘기는 PROPS
  const [courseDelete, setCourseDelete] = useState<Optional<boolean>>(null)
  // 토스트 메세지 및 코스 삭제에서 사용?
  const [courseName, setCourseName] = useState<Optional<string>>(null)
  // 카카오 맵으로 넘김
  const [cancelCloseModifyModal, setCancelCloseModifyModal] =
    useState<boolean>(false)
  const [resetCourseRouteData, setResetCourseRouteData] =
    useState<boolean>(false)
  const [error, setError] = useState<Optional<string>>(null)

  const [reloadOriginalCourse, setReloadOriginalCourse] =
    useState<boolean>(false)

  const handleSubmit = () => {
    addCourseRef.current?.onSubmit()
  }

  const toastMessage = useMemo(() => {
    if (toast === 'station-add') return '정류장 추가가 완료되었습니다.'
    if (toast === 'course-delete') return `${courseName} 노선이 삭제 되었습니다.`
    if (toast === 'course-modify') return `${courseName} 노선 수정이 완료 되었습니다.`
    if (toast === 'course-add') return `노선이 생성 되었습니다.`
    return ''
  }, [toast, courseName])

  const showDeleteModal = useCallback(() => {
    deleteRef.current().show()
  }, [])

  const showModifyModal = useCallback(() => {
    modifyRef.current().show()
  }, [])

  const hideModal = useCallback(() => {
    hideAll();
    setDeleteStatus(false)
    setModifyCourseStatus(false)
    setCopyCourseStatus(false)
    setDisableCirculation(false)
  }, [])

  const deleteConfirmHide = useCallback(() => {
    deleteConfirmRef.current().hide()
    setStationDeleteRefresh(true)
  }, [setStationDeleteRefresh])

  // 정류장 삭제 API
  const deleteStationData = useCallback(() => {
    const data = {
      academyID: selectedAcademyID,
      stationID: station?.id.toString(),
    }
    deleteStation(data)
      .then(res => {
        if (res === 'OK') {
          deleteRef.current().hide()
          deleteConfirmRef.current().show()
        }
      })
      .catch(() => {
        deleteRef.current().hide()
        failedDeleteRef.current().show()
      })
  }, [station])

  // 정류장 수정 API
  const putStationData = useCallback((modifyName: string, modifyType: string) => {
    const data = {
      academyID: selectedAcademyID,
      stationID: station?.id.toString(),
      address: station?.address,
      name: modifyName,
      roadAddress: station?.roadAddress,
      type: modifyType,
      lat: station.lat,
      lng: station.lng,
    }
    putStation(data)
      .then(res => {
        if (res === 'OK') {
          setRefresh(true)
          modifyRef.current().hide()
          modifyConfirmRef.current().show()
        }
      })
      .catch(error => {
        setError(error.response?.data.data)
      })
  }, [station])

  const handleDeleteCourse = useCallback(() => {
    setDeleteStatus(false)
    setCourseDelete(true)
  }, [])

  const handleCloseModifyCourseModal = useCallback(() => {
    setCancelCloseModifyModal(true)
    cancelCourseModifyRef.current().hide()
    setModifyCourseStatus(false)

    setReloadOriginalCourse(true)
  }, [])

  const handleCloseCopyCourseModal = useCallback(() => {
    setCancelCloseModifyModal(true)
    cancelCourseCopyRef.current().hide()
    setCopyCourseStatus(false)

    setReloadOriginalCourse(true)
  }, [])

  const closeConfirmModifyCourseModal = useCallback(() => {
    setConfirmModifyCourseModal(false)
  }, [])

  const closeConfirmAddCourseModal = useCallback(() => {
    setConfirmAddCourseModal(false)
  }, [])

  useEffect(() => {
    if (confirmModifyCourseModal) confirmCourseModifyRef.current().show()
    else confirmCourseModifyRef.current().hide()
  }, [confirmModifyCourseModal])
  useEffect(() => {
    if (confirmAddCourseModal) confirmAddCourseRef.current().show()
    else confirmAddCourseRef.current().hide()
  }, [confirmAddCourseModal])

  useEffect(() => {
    if (deleteStatus) showModalByKey('courseDelete')
    if (courseDelete) hideModalByKey('courseDelete')
    if (modifyCourseStatus) showModalByKey('cancelCourseModify')
    else hideModalByKey('cancelCourseModify')
    if (copyCourseStatus) showModalByKey('cancelCourseCopy')
    else hideModalByKey('cancelCourseCopy')
    if (disableCirculation) showModalByKey('disableCirculation')
  }, [
    deleteStatus,
    courseDelete,
    modifyCourseStatus,
    copyCourseStatus,
    disableCirculation,
    showModalByKey,
    hideModalByKey,
  ])

  useEffect(() => {
    if (toast) {
      const timeoutId = setTimeout(() => {
        setToast(null)
      }, 3000)
      return () => clearTimeout(timeoutId)
    }
  }, [toast])

  return (
    <ScreenContainer>
      <KakaoMap
        showDeleteModal={showDeleteModal}
        showModifyModal={showModifyModal}
        refresh={refresh}
        setRefresh={setRefresh}
        stationDeleteRefresh={stationDeleteRefresh}
        setStationDeleteRefresh={setStationDeleteRefresh}
        setDeleteStatus={setDeleteStatus}
        setModifyCourseStatus={setModifyCourseStatus}
        setCopyCourseStatus={setCopyCourseStatus}
        courseDelete={courseDelete}
        setCourseDelete={setCourseDelete}
        setCourseName={setCourseName}
        cancelCloseModifyModal={cancelCloseModifyModal}
        setCancelCloseModifyModal={setCancelCloseModifyModal}
        setDisableCirculation={setDisableCirculation}
        resetCourseRouteData={resetCourseRouteData}
        setResetCourseRouteData={setResetCourseRouteData}
        setConfirmModifyCourseModal={setConfirmModifyCourseModal}
        setConfirmAddCourseModal={setConfirmAddCourseModal}
        addCourseRef={addCourseRef}
        reloadOriginalCourse={reloadOriginalCourse}
        setReloadOriginalCourse={setReloadOriginalCourse}
      />

      <HalfPopup
        ref={deleteRef.ref}
        contents={
          <DeleteStation
            onClose={hideModal}
            station={station}
            deleteStation={deleteStationData}
          />
        }
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={modifyRef.ref}
        contents={
          <ModifyStation
            onClose={hideModal}
            putStationData={putStationData}
            error={error}
          />
        }
        top={'25%'}
        right={'60%'}
        width={'40rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={modifyConfirmRef.ref}
        contents={<ConfirmModifyStation onClose={hideModal} />}
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={failedDeleteRef.ref}
        contents={<FailedDeleteStation onClose={hideModal} />}
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={deleteConfirmRef.ref}
        contents={<DeleteConfirm onClose={deleteConfirmHide} />}
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={courseDeleteRef.ref}
        contents={
          <CourseDelete
            onClose={hideModal}
            deleteCourse={handleDeleteCourse}
            courseName={courseName}
          />
        }
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={cancelCourseModifyRef.ref}
        contents={
          <CancelModifyCourse
            onClose={hideModal}
            cancelModifyCourse={handleCloseModifyCourseModal}
          />
        }
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={cancelCourseCopyRef.ref}
        contents={
          <CancelCopyCourse
            onClose={hideModal}
            cancelCopyCourse={handleCloseCopyCourseModal}
          />
        }
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={confirmCourseModifyRef.ref}
        contents={
          <ConfirmModifyCourse
            onClose={closeConfirmModifyCourseModal}
            handleSubmit={handleSubmit}
          />
        }
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={confirmAddCourseRef.ref}
        contents={
          <ConfirmAddCourse
            onClose={closeConfirmAddCourseModal}
            handleSubmit={handleSubmit}
          />
        }
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={disableCirculationRef.ref}
        contents={<DisableCirculation onClose={hideModal} />}
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <Toast
        text={toastMessage}
        status={toast ? 'toast' : ''}
      />

    </ScreenContainer>
  )
}

const StationManagement = forwardRef(StationManagementBase)
export default StationManagement
