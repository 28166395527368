import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { flexColumn } from '../../../style/CommonStyle'
import { Optional } from '../../../type/Common'
import { getStationsWithAcademyCategory } from '../../../service/station/Station'
import { useRecoilState, useRecoilValue } from 'recoil'
import { academyIDState } from '../../../recoil/Atom'
import { OperationTypeEnum } from '../../../enum/OperationTypeEnum'
import { CCourseDetail, CStation } from '../../../model/Station'
import StationSideBarDeparture, {
  StationSideBarDepartureRef,
} from './StationSideBarDeparture'
import StationSideBarArrival, {
  StationSideBarArrivalRef,
} from './StationSideBarArrival'
import useSecureRef from '../../../hook/useSecureRef'
import StationSideBarStation from './StationSideBarStation'
import { isEmptyArray, isNil } from '../../../util/ValidationUtil'
import { Coordinate } from '../kakaoMap/KakaoMap'
import { alertError } from '../../../util/ErrorUtil'
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import { stationAddInfoAtom } from '../atom/station-management'

export type StationInfo = {
  stationId: number
  spendTime: number
  lat: number
  lng: number
  name: string
}

type StationSideBarCourseSettingProps = {
  operationType: OperationTypeEnum
  onChangeStationInfos(sis: StationInfo[]): void
  setFixedCenterLatLng(center: { lat: number; lng: number }): void
  onChangeCoordinate: (stations: Coordinate[]) => void
  isSufficient: boolean
  modifyType: Optional<number>
  courseDetail?: CCourseDetail
  addNewCourse: boolean
  setStationList: React.Dispatch<React.SetStateAction<CStation[]>>
  selectedStation: CStation
  setSelectedStation: React.Dispatch<React.SetStateAction<Optional<CStation>>>
  addNewStation: boolean
  setAddNewStation: React.Dispatch<React.SetStateAction<boolean>>
  centerStation: CStation[]
}

export type StationSideBarCourseSettingRef = {
  getStationInfos(): StationInfo[]
  addStationInfo(): any
}

function StationSideBarCourseSettingBase(
  props: StationSideBarCourseSettingProps,
  ref: ForwardedRef<StationSideBarCourseSettingRef>,
): ReactElement {
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const [stationAddInfo, setStationAddInfo] = useRecoilState(stationAddInfoAtom);

  const modalRef = useRef<HTMLDivElement>(null)
  const departureRef = useSecureRef<StationSideBarDepartureRef>(
    '[StationSideBarCourseSetting.tsx] departureRef',
  )
  const arrivalRef = useSecureRef<StationSideBarArrivalRef>(
    '[StationSideBarCourseSetting.tsx] arrivalRef',
  )

  const [stations, setStations] = useState<CStation[]>([])
  const [extraStationInfos, setExtraStationInfos] = useState<
    Optional<StationInfo>[]
  >([])

  const [selectedIdx, setSelectedIdx] = useState<Optional<number>>(null)
  const [selectedDepartureStation, setSelectedDepartureStation] =
    useState<Optional<CStation>>(null)
  const [selectedArrivalStation, setSelectedArrivalStation] =
    useState<Optional<CStation>>(null)

  const fetchStations = useCallback(() => {
    getStationsWithAcademyCategory(selectedAcademyID)
      .then(res => {
        setStations(res)
      })
      .catch(error => {
        throw new Error(
          `getStationsWithAcademyCategory() failed. (data: ${JSON.stringify(
            selectedAcademyID,
          )}, error: ${error})`,
        )
      })
  }, [props.addNewStation])

  const handleSelectDepartureStation = useCallback(
    (s: CStation) => {
      setSelectedDepartureStation(s)
    },
    [setSelectedDepartureStation],
  )


  const removeStationInfo = useCallback(
    (idx: number) => {
      setExtraStationInfos(prev => prev.filter((_, index) => idx !== index))
    },
    [setExtraStationInfos],
  )
  const updateStationInfo = useCallback(
    (s: Optional<StationInfo>, idx: number) => {
      setExtraStationInfos(prev =>
        prev.map((si: StationInfo, index: number) => {
          if (idx === index) {
            return s
          }

          return si
        }),
      )
    },
    [setExtraStationInfos, extraStationInfos],
  )

  const addStationInfo = useCallback(() => {
    if (extraStationInfos.includes(null)) {
      alert('이미 빈 정류장이 존재합니다.')
      return
    }
    setExtraStationInfos(prev => [...prev, null])
  }, [setExtraStationInfos, extraStationInfos])

  useEffect(() => {
    if (stationAddInfo) {
      const { station, type } = stationAddInfo;

      // 경유 정류장만 해당 useEffect에서 처리
      if (type !== '경유') return;

      addStationInfo()
      const selectedStation = {
        stationId: station.id,
        spendTime: station.spendTime,
        lat: station.lat,
        lng: station.lng,
        name: station.name,
      }

      updateStationInfo(selectedStation, extraStationInfos.length)
      setStationAddInfo(null);
    }
  }, [stationAddInfo])

  const getTotalStationInfos = useCallback(() => {
    const departureStationInfo = departureRef.current().getStationInfo()
    const arrivalStationInfo = arrivalRef.current().getStationInfo()
    const tsis = [
      departureStationInfo,
      ...extraStationInfos,
      arrivalStationInfo,
    ]

    if (tsis.some(t => isNil(t))) {
      alertError('required field not filled.', 'required field not filled.')
    }
    return [departureStationInfo, ...extraStationInfos, arrivalStationInfo]
  }, [extraStationInfos])

  const onChangeDeparture = useCallback(
    (s: Optional<CStation>, st: Optional<number>) => {
      const arrivalStationInfo = arrivalRef.current().getStationInfo()

      let departureStationInfo = null
      if (!isNil(s) && !isNil(st)) {
        departureStationInfo = {
          stationId: s.id,
          spendTime: st,
          lat: s.lat,
          lng: s.lng,
          name: s.name,
        }
      }

      const sis: StationInfo[] = [
        departureStationInfo,
        ...extraStationInfos,
        arrivalStationInfo,
      ]
      console.log(sis)
      props.onChangeStationInfos(sis)
    },
    [props.onChangeStationInfos, extraStationInfos],
  )

  const onChangeArrival = useCallback(
    (s: Optional<CStation>) => {
      const departureStationInfo = departureRef.current().getStationInfo()

      let arrivalStationInfo = null
      if (!isNil(s)) {
        arrivalStationInfo = {
          stationId: s.id,
          spendTime: s.spendTime,
          lat: s.lat,
          lng: s.lng,
          name: s.name,
        }
      }

      const sis: StationInfo[] = [
        departureStationInfo,
        ...extraStationInfos,
        arrivalStationInfo,
      ]

      props.onChangeStationInfos(sis)
    },
    [props.onChangeStationInfos, extraStationInfos],
  )

  const handleShowRoute = useCallback(() => {
    const stationInfos = getTotalStationInfos()

    props.onChangeCoordinate(stationInfos)
  }, [getTotalStationInfos])

  useEffect(() => {
    const dsi = departureRef.current().getStationInfo()
    const asi = arrivalRef.current().getStationInfo()

    props.onChangeStationInfos([dsi, ...extraStationInfos, asi])
  }, [extraStationInfos, props.onChangeStationInfos])

  useEffect(() => {
    fetchStations()
  }, [])

  useEffect(() => {
    if (props.addNewStation) {
      fetchStations()
      props.setAddNewStation(false)
    }
  }, [props.addNewStation])

  useImperativeHandle(
    ref,
    () => ({
      getStationInfos: getTotalStationInfos,
      addStationInfo: addStationInfo,
    }),
    [getTotalStationInfos, addStationInfo],
  )

  useEffect(() => {
    if (props.courseDetail) {
      const extraArr = props.courseDetail.course.stations
        .slice(1, -1)
        .map(s => ({
          stationId: s.id,
          spendTime: s.spendTime,
          lat: s.lat,
          lng: s.lng,
          name: s.name,
        }))
      setExtraStationInfos(extraArr)
    }
  }, [props.courseDetail])

  useEffect(() => {
    if (!isEmptyArray(stations)) {
      props.setStationList(stations)
    }
  }, [stations])

  // 새로운 노선 추가 일 때, opearationType이 변경될 때 extraStationInfos 초기화
  useEffect(() => {
    if (props.addNewCourse && !props.modifyType) setExtraStationInfos([])
  }, [props.operationType, props.modifyType, props.addNewCourse])

  const onDragEnd = useCallback((result: DropResult, _: ResponderProvided) => {
    const dt = result.destination
    const selectedStation = JSON.parse(result.draggableId) // 선택한 정류장 ID
    if (!isNil(dt)) {
      setExtraStationInfos(prev => {
        const movedItem =
          prev.find(
            el => el && el.stationId === selectedStation.dragStationIdx,
          ) || null
        const filteredArray = prev.filter(
          el => el?.stationId !== selectedStation.dragStationIdx,
        )

        const result = [
          ...filteredArray.slice(0, dt.index),
          movedItem,
          ...filteredArray.slice(dt.index),
        ]
        return result
      })
    }
  }, [])

  return (
    <CourseSettingWrapper ref={modalRef}>
      <StationSideBarDeparture
        ref={departureRef.ref}
        stations={stations}
        operationType={props.operationType}
        setFixedCenterLatLng={props.setFixedCenterLatLng}
        onChangeDeparture={onChangeDeparture}
        addStationInfo={addStationInfo}
        modifyType={props.modifyType}
        courseDetail={props.courseDetail}
        addNewCourse={props.addNewCourse}
        selectedArrivalStation={selectedArrivalStation}
        selectedIdx={selectedIdx}
        setSelectedIdx={setSelectedIdx}
        setSelectedDepartureStation={setSelectedDepartureStation}
        handleSelectDepartureStation={handleSelectDepartureStation}
        checkedStation={props.selectedStation}
        handleSelectedStation={props.setSelectedStation}
        centerStation={props.centerStation}
      />
      {/* 출발, 도착 제외 정류장들 */}
      <DragDropContext onDragEnd={onDragEnd}>
        {extraStationInfos.map((station, idx: number) => {
          return (
            <StationSideBarStation
              key={`station_${idx}`}
              index={idx}
              stations={stations}
              operationType={props.operationType}
              setFixedCenterLatLng={props.setFixedCenterLatLng}
              onDelete={() => removeStationInfo(idx)}
              onChangeStationInfo={updateStationInfo}
              addStationInfo={addStationInfo}
              modifyType={props.modifyType}
              extraStation={station}
              selectedIdx={selectedIdx}
              setSelectedIdx={setSelectedIdx}
              checkedStation={props.selectedStation}
              handleSelectedStation={props.setSelectedStation}
              centerStation={props.centerStation}
            />
          )
        })}
      </DragDropContext>
      {/* 도착 정류장 로직 */}
      <StationSideBarArrival
        ref={arrivalRef.ref}
        stations={stations}
        operationType={props.operationType}
        setFixedCenterLatLng={props.setFixedCenterLatLng}
        onChangeArrival={onChangeArrival}
        modifyType={props.modifyType}
        courseDetail={props.courseDetail}
        addNewCourse={props.addNewCourse}
        selectedDepartureStation={selectedDepartureStation}
        setSelectedArrivalStation={setSelectedArrivalStation}
        selectedIdx={selectedIdx}
        setSelectedIdx={setSelectedIdx}
        checkedStation={props.selectedStation}
        handleSelectedStation={props.setSelectedStation}
        centerStation={props.centerStation}
      />
      <ShowRouteButton
        status={false}
        onClick={props.isSufficient ? () => handleShowRoute() : () => { }}
        isSufficient={props.isSufficient}>
        노선 확인
      </ShowRouteButton>
    </CourseSettingWrapper>
  )
}

const StationSideBarCourseSetting = forwardRef(StationSideBarCourseSettingBase)
export default StationSideBarCourseSetting

const CourseSettingWrapper = styled.div`
  padding-top: 0.6rem;
  ${flexColumn};
  row-gap: 0.8rem;
`

type ButtonStatus = {
  status: boolean
  isSufficient: boolean
}

const ShowRouteButton = styled.button<ButtonStatus>`
  border: none;
  border-radius: 1.6rem;
  width: 5.6rem;
  height: 1.8rem;
  padding: 0.2rem 0.8rem;
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 500;
  background: ${props => (props.isSufficient ? '#FFD100' : '#fff1b3')};
  color: ${props => (props.isSufficient ? '#332A00' : '#cccccc')};
  cursor: ${props => (props.isSufficient ? 'pointer' : 'not-allowed')};
  margin-left: 17.2rem;
`
