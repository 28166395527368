import useSecureRef from "../../../hook/useSecureRef"
import { ModalContainerRef } from "../../modal/ModalContainer"

/**
 * 모달 레퍼런스를 객체 형태로 한데 모읍니다.
 * - showModal("delete") 처럼 간단히 호출 가능
 * - hideAll()시 전체 모달을 순회하며 hide
 */
export function useStationManagementModals() {
  const refs = {
    delete: useSecureRef<ModalContainerRef>('[StationManagement.tsx] deleteRef'),
    deleteConfirm: useSecureRef<ModalContainerRef>('[StationManagement.tsx] deleteConfirmRef'),
    modify: useSecureRef<ModalContainerRef>('[StationManagement.tsx] modifyRef'),
    modifyConfirm: useSecureRef<ModalContainerRef>('[StationManagement.tsx] modifyConfirmRef'),
    failedDelete: useSecureRef<ModalContainerRef>('[StationManagement.tsx] failedDeleteRef'),
    courseDelete: useSecureRef<ModalContainerRef>('[StationManagement.tsx] courseDeleteRef'),
    cancelCourseModify: useSecureRef<ModalContainerRef>('[StationManagement.tsx] cancelCourseModifyRef'),
    cancelCourseCopy: useSecureRef<ModalContainerRef>('[StationManagement.tsx] cancelCourseCopyRef'),
    confirmCourseModify: useSecureRef<ModalContainerRef>('[StationManagement.tsx] confirmCourseModifyRef'),
    confirmAddCourse: useSecureRef<ModalContainerRef>('[StationManagement.tsx] confirmAddCourseRef'),
    disableCirculation: useSecureRef<ModalContainerRef>('[StationManagement.tsx] disableCirculationRef'),
  }

  /**
   * 모든 모달 hide
   */
  function hideAll() {
    Object.values(refs).forEach((ref) => {
      ref.current().hide()
    })
  }

  /**
   * 특정 모달 show
   */
  function showModalByKey(key: keyof typeof refs) {
    refs[key].current().show()
  }

  /**
   * 특정 모달 hide
   */
  function hideModalByKey(key: keyof typeof refs) {
    refs[key].current().hide()
  }

  return {
    ...refs,
    hideAll,
    showModalByKey,
    hideModalByKey,
  }
}