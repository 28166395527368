import React, {
  ForwardedRef,
  forwardRef,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'
import DragIcon from '../../../asset/image/drag_icon.svg'
import StationIcon from '../../../asset/image/selected_station_none_shadow.svg'
import StationSideBarSelect, { StationSelectRef } from './StationSideBarSelect'
import PinIcon from '../../../asset/image/pin.svg'
import DisabledPinIcon from '../../../asset/image/disabled_pin.svg'
import YellowPinIcon from '../../../asset/image/pin_yellow.svg'
import DeleteIcon from '../../../asset/image/close_bold.svg'
import styled from 'styled-components'
import { flexColumn, flexRow } from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import useSecureRef from '../../../hook/useSecureRef'
import { CStation } from '../../../model/Station'
import { OperationTypeEnum } from '../../../enum/OperationTypeEnum'
import { Optional } from '../../../type/Common'
import { isEmptyArray, isNil } from '../../../util/ValidationUtil'
import { StationInfo } from './StationSideBarCourseSetting'
import StationSideBarTimeInputContainer from './StationSideBarTimeInputContainer'
import { Droppable, Draggable } from 'react-beautiful-dnd'

type StationSideBarStationProps = {
  index: number
  operationType: OperationTypeEnum
  stations: CStation[]
  setFixedCenterLatLng(center: { lat: number; lng: number }): void
  onDelete(): void
  onChangeStationInfo(s: StationInfo, index: number): void
  addStationInfo(): void
  modifyType: Optional<number>
  extraStation: Optional<StationInfo>
  selectedIdx: Optional<number>
  setSelectedIdx: React.Dispatch<React.SetStateAction<number>>
  checkedStation: Optional<CStation>
  handleSelectedStation: React.Dispatch<
    React.SetStateAction<Optional<CStation>>
  >
  centerStation: CStation[]
}

export type StationSideBarStationRef = {}

function StationSideBarStationBase(
  props: StationSideBarStationProps,
  _: ForwardedRef<StationSideBarStationRef>,
): ReactElement {
  const selectRef = useSecureRef<StationSelectRef>(
    '[StationSideBarStation.tsx] selectRef',
  )

  const [selectedStation, setSelectedStation] =
    useState<Optional<CStation>>(null)
  const [spendTime, setSpendTime] = useState<Optional<number>>(null)

  const handleCenterLatLng = useCallback(() => {
    if (isNil(selectedStation)) {
      return
    }

    props.setSelectedIdx(props.index + 1)
    props.setFixedCenterLatLng({
      lat: selectedStation.lat,
      lng: selectedStation.lng,
    })
    props.handleSelectedStation(null)
  }, [props.setFixedCenterLatLng, selectedStation, props.setSelectedIdx])

  const onChangeSpendTime = useCallback(
    (st: number) => {
      if (isNil(st)) {
        setSpendTime(null) // spendTime을 null로 설정
        props.onChangeStationInfo(
          {
            stationId: selectedStation?.id,
            spendTime: null, // spendTime을 null로 설정하여 부모 컴포넌트에 전달
            lat: selectedStation?.lat,
            lng: selectedStation?.lng,
            name: selectedStation?.name,
          },
          props.index,
        )
        return
      }

      setSpendTime(st) // spendTime 업데이트
      props.onChangeStationInfo(
        {
          stationId: selectedStation?.id,
          spendTime: st,
          lat: selectedStation?.lat,
          lng: selectedStation?.lng,
          name: selectedStation?.name,
        },
        props.index,
      )
    },
    [setSpendTime, selectedStation, props.index, props.onChangeStationInfo],
  )

  const onUpdateStation = useCallback(
    (s: CStation) => {
      setSelectedStation(s)
      setSpendTime(null) // spendTime을 초기화

      props.onChangeStationInfo(
        {
          stationId: s.id,
          spendTime: null, // spendTime을 null로 설정하여 부모 컴포넌트에 전달
          lat: s.lat,
          lng: s.lng,
          name: s.name,
        },
        props.index,
      )
    },
    [props.onChangeStationInfo, props.index],
  )

  useEffect(() => {
    if (props.extraStation) {
      setSelectedStation({
        type: 'NORMAL',
        id: props.extraStation.stationId,
        name: props.extraStation.name,
        roadAddress: '',
        address: '',
        lat: props.extraStation.lat,
        lng: props.extraStation.lng,
        spendTime: props.extraStation.spendTime,
      })
    } else {
      setSelectedStation(null)
    }
  }, [props.extraStation])

  return (
    <Droppable
      key={`${props?.extraStation?.stationId}`}
      droppableId={`${props?.extraStation?.stationId}`}>
      {(provided, snapshot) => (
        <StationWrapper
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{
            background: snapshot.isDraggingOver ? '#fffbe5' : '',
            borderRadius: '1rem',
          }}>
          <Draggable
            draggableId={`${JSON.stringify({
              dragStationIdx: props?.extraStation?.stationId,
            })}`}
            key={
              isNil(props?.extraStation?.stationId)
                ? props.index
                : props?.extraStation?.stationId
            }
            index={props.index}>
            {(provided, _) => (
              <Station
                ref={provided.innerRef}
                {...provided.dragHandleProps}
                {...provided.draggableProps}>
                <DepartureStation>
                  <ButtonIcon source={DragIcon} />
                  <StationImgWrapper>
                    <DefaultImg source={StationIcon} />
                    <Idx>{props.index + 1}</Idx>
                  </StationImgWrapper>
                  <StationSideBarSelect
                    ref={selectRef.ref}
                    onChange={onUpdateStation}
                    selectedStation={selectedStation}
                  />
                  <IconWrapper onClick={handleCenterLatLng}>
                    <MapIcon
                      selectedStation={selectedStation}
                      src={
                        isNil(selectedStation)
                          ? DisabledPinIcon
                          : isEmptyArray(props.centerStation)
                            ? PinIcon
                            : props.index === props.selectedIdx - 1 &&
                              isNil(props.checkedStation)
                              ? YellowPinIcon
                              : PinIcon
                      }
                    />
                  </IconWrapper>
                  <Line />
                  <IconWrapper onClick={props.onDelete}>
                    <ButtonIcon source={DeleteIcon} />
                  </IconWrapper>
                </DepartureStation>
                <StationSideBarTimeInputContainer
                  selectedStation={selectedStation}
                  value={
                    selectedStation?.spendTime !== null
                      ? selectedStation?.spendTime
                      : null
                  } // spendTime이 null일 때 빈 문자열로 설정
                  addStationInfo={props.addStationInfo}
                  onChange={onChangeSpendTime}
                />
              </Station>
            )}
          </Draggable>
        </StationWrapper>
      )}
    </Droppable>
  )
}

const StationSideBarStation = forwardRef(StationSideBarStationBase)
export default StationSideBarStation

const StationWrapper = styled.div``

const Station = styled.div`
  ${flexColumn};
  row-gap: 0.8rem;
`

const DepartureStation = styled.div`
  position: relative;
  ${flexRow};
  height: 3rem;
  column-gap: 0.2rem;
  align-items: center;
`
type PinProps = {
  selectedStation?: Optional<CStation>
}

const IconWrapper = styled.div``

const Line = styled.div`
  width: 0.1rem;
  height: 1.6rem;
  background: #ebebeb;
`

const MapIcon = styled.img<PinProps>`
  width: 1.8rem;
  height: 1.8rem;
  cursor: ${props =>
    isNil(props.selectedStation) ? 'not-allowed' : 'pointer'};
`

const ButtonIcon = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const DefaultImg = styled(SVGImage)`
  width: 2.4rem;
  height: 2.4rem;
`

const StationImgWrapper = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  position: relative;
  cursor: pointer;
`

const Idx = styled.div`
  position: absolute;
  top: 18%;
  left: 0.95rem;
  color: #665300;
  font-size: 0.8667rem;
  line-height: 150%;
  font-weight: 700;
`
