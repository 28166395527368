import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { isEmptyString, isNil } from '../../../util/ValidationUtil'
import { flexColumn, flexRow } from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import { SearchStationEnum } from '../../../enum/SearchStationEnum'
import { Optional } from '../../../type/Common'
import {
  CAddressVector,
  CCourseDetail,
  CDefaultCourse,
  CSearchList,
  CSearchPlace,
  CStation,
  CStationDetail,
} from '../../../model/Station'
import {
  getSearchCourseDetail,
  getSearchStationDetail,
  getStationInfo,
} from '../../../service/station/Station'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { academyIDState } from '../../../recoil/Atom'
import { PlusIconImg, RouteButton } from './StationSideBarEmpty'

// image
import StationIcon from '../../../asset/image/list_station.svg'
import RouteIcon from '../../../asset/image/list_course.svg'
import LocationIcon from '../../../asset/image/list_location.svg'
import PlusIcon from '../../../asset/image/plus_icon.svg'
import BusIcon from '../../../asset/image/bus_gray.svg'
import BusStopIcon from '../../../asset/image/busstop_gray.svg'
import RightArrow from '../../../asset/image/right_arrow_black.svg'
import { StationInfo } from './StationSideBarCourseSetting'
import { stationAtom } from '../atom/station-management'

export type CourseProps = {
  id: number
  name: string
  dispatchType: string
}

type SeatchListProps = {
  select: string
  stationList: Optional<CSearchList[]>
  setDetailCard: React.Dispatch<React.SetStateAction<boolean>>
  setDetailCardName: React.Dispatch<React.SetStateAction<string>>
  setStationDetail: React.Dispatch<React.SetStateAction<CStationDetail>>
  courseDetail: CCourseDetail
  setCourseDetail: React.Dispatch<React.SetStateAction<CCourseDetail>>
  setCancelCloseModifyModal?: React.Dispatch<React.SetStateAction<boolean>>
  addressList: CAddressVector[]
  courseList: CourseProps[]
  placeList: CSearchPlace[]
  refresh: boolean
  selectedStation?: CStation
  isShowStationDetail: boolean

  fixedCenterLatLng: (center: { lat: number; lng: number }) => void
  fixedMarker: (lat: number, lng: number) => void
  keyword: string
  defaultCourseList: CDefaultCourse[]
  handleAddCourseButton: () => void
  totalElementCount?: number
  card: CourseProps | CDefaultCourse
  setCard?: React.Dispatch<React.SetStateAction<CourseProps | CDefaultCourse>>
  detailCard: boolean
  getCourseRoute: (cs: StationInfo[]) => void
  semesterID: Optional<number>
  dispatchPeriodId: Optional<number>
  selectedYear: number
  reloadOriginalCourse: boolean
  setReloadOriginalCourse: React.Dispatch<React.SetStateAction<boolean>>
}

function SearchList(props: SeatchListProps) {
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const setStation = useSetRecoilState(stationAtom)
  const [cardID, setCardID] = useState<Optional<number>>(null)
  const [idx, setIdx] = useState<Optional<number>>(null)

  const handleClickStationCard = useCallback(
    (card: CSearchList) => {
      setCardID(card.id)
      props.fixedCenterLatLng({ lat: card.lat, lng: card.lng })
      props.setDetailCard(true)
      props.setDetailCardName(card.name)
      setStation({
        id: card.id,
        name: card.name,
        roadAddress: card.roadAddress,
        address: card.address,
        lat: card.lat,
        lng: card.lng,
        type: card.type,
      })
      getSearchStationDetail(
        selectedAcademyID,
        String(card.id),
        props.dispatchPeriodId,
        props.selectedYear,
      )
        .then(res => {
          props.setStationDetail(res)
        })
        .catch(error => {
          throw new Error(
            `failed to get station Detail . (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
    },
    [
      setStation,
      props.stationList,
      props.setDetailCard,
      props.setDetailCardName,
      selectedAcademyID,
      props.selectedYear,
    ],
  )

  const handleClickPlace = useCallback(
    (card: CSearchPlace, i: number) => {
      setIdx(i)
      props.fixedCenterLatLng({ lat: card.lat, lng: card.lng })
      props.fixedMarker(card.lat, card.lng)
      props.setDetailCard(false)
      props.setDetailCardName(null)
    },
    [props.setDetailCard, props.setDetailCardName, selectedAcademyID],
  )

  useEffect(() => {
    if (props.refresh) {
      getSearchStationDetail(
        selectedAcademyID,
        String(cardID),
        props.dispatchPeriodId,
        props.selectedYear,
      )
        .then(res => {
          props.setStationDetail(res)
        })
        .catch(error => {
          throw new Error(
            `failed to get station Detail . (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
    }
  }, [props.refresh])

  useEffect(() => {
    if (!isNil(cardID)) {
      const data = {
        academyID: selectedAcademyID,
        stationID: String(cardID),
        dispatchPeriodId: props.dispatchPeriodId,
        year: props.selectedYear,
      }
      getStationInfo(data).then(res =>
        setStation({
          id: cardID,
          name: res.station.name,
          roadAddress: res.station.roadAddress,
          address: res.station.address,
          lat: res.station.lat,
          lng: res.station.lng,
          type: res.station.type,
        }),
      )
    }
  }, [props.stationList, props.dispatchPeriodId])

  function SearchStationIcon() {
    return (
      <Icon
        source={
          props.select === SearchStationEnum.NAME.value
            ? StationIcon
            : props.select === SearchStationEnum.ROUTE.value
              ? RouteIcon
              : LocationIcon
        }
      />
    )
  }

  const handleClickAddressCard = useCallback(
    (card: CAddressVector, i: number) => {
      props.fixedCenterLatLng({ lat: card.lat, lng: card.lng })
      setIdx(i)
      props.fixedMarker(card.lat, card.lng)
      props.setDetailCard(false)
      props.setDetailCardName(null)
    },
    [props.setDetailCard, props.setDetailCardName, selectedAcademyID],
  )

  const handleClickCourseCard = useCallback(
    (card: CourseProps | CDefaultCourse) => {
      props.setCard(card)
      setIdx(card.id)
      props.setDetailCard(true)
      props.setDetailCardName(card.name)
      props.setCancelCloseModifyModal(true)
      getSearchCourseDetail(
        selectedAcademyID,
        String(card.id),
        props.dispatchPeriodId,
        props.selectedYear,
      )
        .then(res => props.setCourseDetail(res))
        .catch(error => {
          throw new Error(
            `failed to get Course Detail . (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
    },
    [props.setDetailCard, selectedAcademyID, props.dispatchPeriodId],
  )

  useEffect(() => {
    if (!isNil(props.courseDetail)) {
      const courseData = props.courseDetail.course.stations.map(s => {
        return {
          lat: s.lat,
          lng: s.lng,
          stationId: s.id,
          spendTime: 0,
          name: s.name,
        }
      })
      props.getCourseRoute(courseData)
      props.fixedCenterLatLng({ lat: courseData[0].lat, lng: courseData[0].lng })
    }
  }, [props.courseDetail, props.fixedCenterLatLng])

  useEffect(() => {
    if (!props.detailCard) {
      setIdx(null)
      setCardID(null)
    }
  }, [props.detailCard])

  useEffect(() => {
    setIdx(null)
    setCardID(null)
  }, [props.select, setIdx, setCardID, selectedAcademyID])

  useEffect(() => {
    if (props.card && props.select === SearchStationEnum.ROUTE.value) {
      handleClickCourseCard(props.card)
    }
  }, [props.defaultCourseList, props.courseList, selectedAcademyID])

  useEffect(() => {
    if (props.card) {
      handleClickCourseCard(props.card)
      props.setReloadOriginalCourse(false)
    }
  }, [props.reloadOriginalCourse])

  return (
    <SearchListWrapper>
      {!isNil(props.stationList) &&
        props.select === SearchStationEnum.NAME.value &&
        props.stationList?.map(card => {
          return (
            <SearchStationCard
              key={card.id}
              onClick={() => handleClickStationCard(card)}
              selected={card.id === cardID}>
              {SearchStationIcon()}
              <CardInfo>
                <Title>{card.name}</Title>
                <SubTitle>{card.roadAddress}</SubTitle>
              </CardInfo>
            </SearchStationCard>
          )
        })}
      {!isNil(props.addressList) &&
        props.select === SearchStationEnum.ADDRESS.value &&
        props.addressList?.map((ad, i) => {
          return (
            <SearchStationCard
              key={i}
              onClick={() => handleClickAddressCard(ad, i)}
              selected={i === idx}>
              {SearchStationIcon()}
              <CardInfo>
                <Title select={SearchStationEnum.ADDRESS.value}>
                  {ad.name}
                </Title>
              </CardInfo>
            </SearchStationCard>
          )
        })}
      {
        // !isNil(props.courseList) &&
        props.select === SearchStationEnum.ROUTE.value &&
          !isNil(props.keyword) ? (
          <SearchedCourseList>
            <RouteListTitleWrapper>
              <RouteTitle>검색 결과</RouteTitle>
              <RouteCnt>{`${props.courseList.length} 개`}</RouteCnt>
            </RouteListTitleWrapper>
          </SearchedCourseList>
        ) : (
          ''
        )
      }
      {!isNil(props.courseList) &&
        props.select === SearchStationEnum.ROUTE.value &&
        !isNil(props.keyword) &&
        props.courseList?.map((cl: CourseProps, i) => {
          return (
            <SearchStationCard
              key={i}
              onClick={() => handleClickCourseCard(cl)}
              selected={cl.id === idx}>
              <RouteLabel type={cl.dispatchType} style={{ marginTop: '0.5rem' }}>
                {cl.dispatchType === 'OUTBOUND'
                  ? '하원'
                  : cl.dispatchType === 'INBOUND'
                    ? '등원'
                    : '순환'}
              </RouteLabel>
              <CardInfo>
                <Title select={SearchStationEnum.ROUTE.value}>{cl.name}</Title>
              </CardInfo>
            </SearchStationCard>
          )
        })}

      {(!isNil(props.defaultCourseList) &&
        props.select === SearchStationEnum.ROUTE.value &&
        isNil(props.keyword)) ||
        (!isNil(props.defaultCourseList) &&
          props.select === SearchStationEnum.ROUTE.value &&
          isEmptyString(props.keyword)) ? (
        <DefaultCourseList>
          <ListHeader>
            <RouteListTitleWrapper>
              <RouteTitle>전체 노선</RouteTitle>
              <RouteCnt>{`${props.totalElementCount} 개`}</RouteCnt>
            </RouteListTitleWrapper>
            <RouteButton onClick={props.handleAddCourseButton}>
              <PlusIconImg source={PlusIcon} />
              노선 추가
            </RouteButton>
          </ListHeader>
          {props.defaultCourseList?.map((cl: CDefaultCourse, i) => {
            return (
              <DefaultRouteCard
                key={i}
                onClick={() => handleClickCourseCard(cl)}>
                <RouteCardTop selected={cl.id === idx}>
                  <RouteCardTitle select={SearchStationEnum.ROUTE.value}>
                    {cl.name}
                  </RouteCardTitle>
                  <RouteInfoIcon source={RightArrow} />
                </RouteCardTop>
                <RouteCardBottom selected={cl.id === idx}>
                  <RouteLabel type={cl.dispatchType}>
                    {cl.dispatchType === 'OUTBOUND'
                      ? '하원'
                      : cl.dispatchType === 'INBOUND'
                        ? '등원'
                        : '순환'}
                  </RouteLabel>
                  <RouteCardInfo>
                    <FlexRow>
                      <RouteInfoIcon source={BusIcon} />
                      <RouteInfoCnt>{`${cl.dispatchCount} 개`}</RouteInfoCnt>
                    </FlexRow>
                    <FlexRow>
                      <RouteInfoIcon source={BusStopIcon} />
                      <RouteInfoCnt>{`${cl.stationCount} 개`}</RouteInfoCnt>
                    </FlexRow>
                  </RouteCardInfo>
                </RouteCardBottom>
              </DefaultRouteCard>
            )
          })}
        </DefaultCourseList>
      ) : (
        ''
      )}
      {!isNil(props.placeList) &&
        props.select === SearchStationEnum.PLACE.value &&
        props.placeList?.map((card, i) => {
          return (
            <SearchStationCard
              key={i}
              onClick={() => handleClickPlace(card, i)}
              selected={i === idx}>
              {SearchStationIcon()}
              <CardInfo>
                <Title>{card.name}</Title>
                <SubTitle>{card.roadAddress}</SubTitle>
              </CardInfo>
            </SearchStationCard>
          )
        })}
    </SearchListWrapper>
  )
}

export default SearchList

const SearchListWrapper = styled.div`
  ${flexColumn};
`

const DefaultCourseList = styled.div`
  padding: 1.6rem;
`

const ListHeader = styled.div`
  ${flexRow};
  justify-content: space-between;
`

const RouteListTitleWrapper = styled.div`
  ${flexRow};
  align-items: center;
`

const RouteTitle = styled.div`
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 2rem;
`

const RouteCnt = styled.div`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4rem;
  color: #666666;
  margin-left: 0.4rem;
`

const DefaultRouteCard = styled.div`
  ${flexColumn};
  border-radius: 0.8rem;
  border: 0.1rem solid #ebebeb;
  margin-top: 0.8rem;
  cursor: pointer;
`

const RouteCardTop = styled.div<CardProps>`
  padding: 0.8rem 1.2rem;
  ${flexRow};
  justify-content: space-between;
  align-items: center;
  background: ${props => (props.selected ? '#FFFBE5' : '#fff')};
  border-bottom: 0.1rem solid #ebebeb;
  border-radius: 0.8rem 0.8rem 0 0;
`

const RouteCardTitle = styled.div<TitleProps>`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
`

const RouteCardBottom = styled.div<CardProps>`
  background: ${props => (props.selected ? '#FFFBE5' : '#f5f5f5')};
  padding: 0.4rem 1.2rem;
  ${flexRow};
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 0.8rem 0.8rem;
`

const RouteCardInfo = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
`

const FlexRow = styled.div`
  ${flexRow};
`

type CardProps = {
  selected?: boolean
}

const SearchStationCard = styled.div<CardProps>`
  padding: 1.2rem 1.6rem;
  ${flexRow};
  border-bottom: 0.1rem solid #ebebeb;
  column-gap: 0.4rem;
  transition: all 0.2s;
  background: ${props => (props.selected ? '#FFFBE5' : '#fff')};

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.03);
  }
`

type LabelProps = {
  type?: string
}
const RouteLabel = styled.div<LabelProps>`
  min-width: 2.8rem;
  max-width: 2.8rem;
  max-height: 1.4rem;
  padding: 0.1rem 0.3rem;
  background: ${props =>
    props.type === 'INBOUND'
      ? '#009A17'
      : props.type === 'OUTBOUND'
        ? '#6092E1'
        : '#FFD100'};
  border: none;
  border-radius: 1.2rem;
  color: ${props =>
    props.type === 'INBOUND' || props.type === 'OUTBOUND' ? '#fff' : '#332a00'};
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 700;
  letter-spacing: -0.024rem;
`

const CardInfo = styled.div`
  ${flexColumn}
  row-gap: 0.2rem;
  width: 90%;
`

type TitleProps = {
  select?: string
}

const Title = styled.div<TitleProps>`
  font-size: 1.3rem;
  font-weight: 400;
  line-height: ${props =>
    props.select === 'ADDRESS' || props.select === 'ROUTE' ? '2.4rem' : '2rem'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SubTitle = styled.div`
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #666;
`

const Icon = styled(SVGImage)`
  width: 2.4rem;
  height: 2.4rem;
`

const RouteInfoIcon = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const RouteInfoCnt = styled.div`
  margin-left: 0.2rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4rem;
  color: #666;
`

const SearchedCourseList = styled.div`
  padding: 1.9rem 1.6rem;
  border-bottom: 1px solid #ebebeb;
`
