import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import StationSideBar from '../stationSideBar/StationSideBar'
import { Optional } from '../../../type/Common'
import { CAcademyData, CCourseDetail, CStation, CStationType, CStationTypeLabels, CStationWithType } from '../../../model/Station'
import {
  PutStationParams,
  getAcademyAddress,
  getAcademyStationListByPeriod,
  getAddress,
  getCourseRoute,
  getStationInfo,
  postAcademyStation,
  putStation,
} from '../../../service/station/Station'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { academyIDState } from '../../../recoil/Atom'
import useSecureRef from '../../../hook/useSecureRef'
import { isEmptyArray, isEmptyString, isNil } from '../../../util/ValidationUtil'
import { StationSideBarAddCourseRef } from '../stationSideBar/StationSideBarAddCourse'

// image
import StationIcon from '../../../asset/image/station_gray.svg'
import StationUsedCourseIcon from '../../../asset/image/station_used_course.svg'
import StationUsedDispatchIcon from '../../../asset/image/station_used_dispatch.svg'
import StationUsedBothIcon from '../../../asset/image/station_used_both.svg'
import SelectedStationIcon from '../../../asset/image/selected_station.svg'
import SelectedStationNoneShadow from '../../../asset/image/selected_station_none_shadow.svg'
import StudentIcon from '../../../asset/image/student.svg'
import RouteIcon from '../../../asset/image/route.svg'
import RightArrow from '../../../asset/image/right_arrow_yellow.svg'
import CloseIcon from '../../../asset/image/close_button.svg'
import CheckIcon from '../../../asset/image/checked_brown.svg'
import UncheckIcon from '../../../asset/image/unchecked.svg'
import TriangleIcon from '../../../asset/image/rectangle.svg'
import AcademyIcon from '../../../asset/image/academy.svg'
import DragIcon from '../../../asset/image/drag_station.svg'
import DeleteIcon from '../../../asset/image/close_yellow.svg'
import DepartureIcon from '../../../asset/image/departure_station_blue.svg'
import ArrivalIcon from '../../../asset/image/arrival_station_black.svg'
import AcademyIcon2 from '../../../asset/image/academy_icon.svg'
import CheckBrownIcon from '../../../asset/image/checked_brown.svg'

// kakao
import { CustomOverlayMap, Map, MapMarker, Polyline } from 'react-kakao-maps-sdk'

import { StationInfo } from '../stationSideBar/StationSideBarCourseSetting'
import DispatchHeaderPicker from '../../dispatch/dispatchPeriod/header/DispatchHeaderPicker'
import { getDispatchPeriodExtractYears } from '../../../service/dispatchPeriod/DispatchPeriod'
import { CDispatchPeriod } from '../../../model/DispatchPeriod'
import { CDate } from '../../../model/Date'
import { AcademyIconImg, AcademyStatusButton, AcademyStatusWrapper, AddButton, Address, AddressBottom, AddressContainer, AddStationModal, AddStationNoti, AddStationWarning, BusStationName, ButtonWrapper, CancelButton, CheckImg, CloseImage, CloseImg, Container, DeleteButton, DeleteButtonWrapper, DetailButton, DispatchHeaderWrapper, IconImg, ImgWrapper, Input, InputAddress, InputWrapper, Li, MapInfo, RightArrowImg, RoadAddress, RouteIdx, SelectAddressWrapper, SelectPlaceImg, SelectStation, SelectStationInfo, StationAddress, StationBorder, StationDivider, StationInfoWrapper, StationName, StationRoadAddress, StationSummary, StationSummaryChip, StationSummarySection, StationSummaryText, StationType, StationTypeSelect, StationTypeSelectItem, StudentRoute, StudentRouteWrapper, Title, TriangleImg, Ul } from './KakaoMapContainer'
import { stationAddInfoAtom, stationAtom, toastAtom } from '../atom/station-management'
import Loading, { LoadingRef } from '../../common/Loading'

//TODO: recoil or context로 관리체계 일괄 변경
type MapProps = {
  showDeleteModal: () => void
  showModifyModal: () => void
  refresh: boolean
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
  stationDeleteRefresh: boolean
  setStationDeleteRefresh: React.Dispatch<React.SetStateAction<boolean>>
  setDeleteStatus?: React.Dispatch<React.SetStateAction<boolean>>
  setModifyCourseStatus?: React.Dispatch<React.SetStateAction<boolean>>
  setCopyCourseStatus?: React.Dispatch<React.SetStateAction<boolean>>
  courseDelete: boolean
  setCourseDelete?: React.Dispatch<React.SetStateAction<boolean>>
  setCourseName?: React.Dispatch<React.SetStateAction<Optional<string>>>
  cancelCloseModifyModal: boolean
  setCancelCloseModifyModal?: React.Dispatch<React.SetStateAction<boolean>>
  setDisableCirculation?: React.Dispatch<React.SetStateAction<boolean>>
  resetCourseRouteData: boolean
  setResetCourseRouteData: React.Dispatch<React.SetStateAction<boolean>>
  setConfirmModifyCourseModal: React.Dispatch<React.SetStateAction<boolean>>
  setConfirmAddCourseModal: React.Dispatch<React.SetStateAction<boolean>>
  addCourseRef?: React.Ref<StationSideBarAddCourseRef>
  reloadDispatchPeriod?: boolean
  reloadOriginalCourse: boolean
  setReloadOriginalCourse: React.Dispatch<React.SetStateAction<boolean>>
}

export type Coordinate = {
  lat: number
  lng: number
}

type AddressProps = {
  address: Optional<string>
  roadAddress: Optional<string>
}

type CenterProps = Coordinate

type DragStationProps = {
  id: number
  name: string
}
const KakaoMap: React.FC<MapProps> = props => {
  const { addCourseRef } = props

  const innerWidth = window.innerWidth
  const innerHeight = window.innerHeight

  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const toast = useSetRecoilState(toastAtom)
  const setStation = useSetRecoilState(stationAtom)

  const mapRef = useSecureRef('[KakaoMap.tsx] mapRef')
  const markerRef = useRef(null)

  const [selectedStation, setSelectedStation] =
    useState<Optional<CStation>>(null)
  const [stationsWithType, setStationsWithType] = useState<CStationWithType[]>([])
  const [stations, setStations] = useState<CStation[]>([])
  const [courseDetail, setCourseDetail] =
    useState<Optional<CCourseDetail>>(null)
  const [isShowStationName, setIsShowStationName] = useState<boolean>(false)
  const [isShowStationDetail, setIsShowStationDetail] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState(false)
  const [markers, setMarkers] = useState<any>([]) // 지도 클릭시 좌표 값
  const [academyStationStatus, setAcademyStationStatus] =
    useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')
  const [address, setAddress] = useState<Optional<AddressProps>>(null)
  const [academyData, setAcademyData] = useState<Optional<CAcademyData>>(null)
  const [center, setCenter] = useState<Optional<CenterProps>>(null)
  const [courseCnt, setCourseCnt] = useState<Optional<number>>(null)
  const [studentCnt, setStudentCnt] = useState<Optional<number>>(null)
  const [dragStation, setDragStation] =
    useState<Optional<DragStationProps>>(null)
  const [isOpenAddCourse, setIsOpenAddCourse] =
    useState<Optional<boolean>>(null)
  const [isOpenModifyCourse, setIsOpenModifyCourse] =
    useState<Optional<boolean>>(null)
  const [addStationWarning, setAddStationWarning] = useState<boolean>(false)
  const [coordinates, setCoordinates] = useState<Coordinate[]>([])
  const [stationModalStatus, setStationModalStatus] = useState<boolean>(false)
  const [containStations, setContainStations] = useState<StationInfo[]>([])
  const [courseRouteData, setCourseRouteData] = useState<any>(null)
  const [centerStation, setCenterStation] = useState<CStation[]>([])
  const [position, setPosition] = useState<Optional<Coordinate>>(null)
  const [currentSelect, setCurrentSelect] = useState<Optional<string>>(null)
  const [stationRefresh, setStationRefresh] = useState<boolean>(false)
  const [error, setError] = useState<Optional<string>>(null)
  const [operationType, setOperationType] = useState<Optional<string>>(null)
  const [routeStationName, setRouteStationName] = useState([])
  const [years, setYears] = useState<number[]>([])
  const [semesterID, setSemesterID] = useState<Optional<number>>(null)
  const [addNewStation, setAddNewStation] = useState<boolean>(false)
  const [originalPosition, setOriginalPosition] = useState<Coordinate>(null)
  const [isStationDragged, setIsStationDragged] = useState(false)
  const [dispatchPeriodId, setDispatchPeriodId] =
    useState<Optional<number>>(null)
  const [stationFilter, setStationFilter] = useState<CStationType | 'ALL'>('ALL');

  const [selectedYear, setSelectedYear] = useState<Optional<number>>(null)

  type Coordinate = {
    lat: number
    lng: number
  }

  const [markerPositions, setMarkerPositions] = useState<
    Record<number, Coordinate>
  >(
    stations.reduce(
      (acc, station) => {
        acc[station.id] = { lat: station.lat, lng: station.lng }
        return acc
      },
      {} as Record<number, Coordinate>,
    ),
  )

  const loadingRef = useRef<LoadingRef | null>(null)

  const handleLoading = useCallback(
    (isLoading: boolean) => {
      if (isLoading === true) {
        loadingRef.current?.show()
      } else {
        loadingRef.current?.hide()
      }
    },
    [loadingRef],
  )


  const handleDragEnd = (stationId: number, map: any) => {
    const newPosition = {
      lat: map.getPosition().getLat(),
      lng: map.getPosition().getLng(),
    }

    if (
      newPosition.lat !== originalPosition.lat ||
      newPosition.lng !== originalPosition.lng
    ) {
      setIsStationDragged(true) // Mark as dragged only when position has changed
    }

    setMarkerPositions(prev => ({
      ...prev,
      [stationId]: newPosition,
    }))
  }

  const closeModal = useCallback(() => {
    setIsOpen(false)
    setSelectedStation(null)
    setIsShowStationDetail(false)
    setIsStationDragged(false)
  }, [selectedAcademyID, dispatchPeriodId])

  useEffect(() => {
    closeModal()
  }, [dispatchPeriodId])

  const handleChangeKeyword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setKeyword(e.target.value)
    },
    [keyword],
  )

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const addCourseStatus = useCallback((v: boolean) => {
    setIsOpenAddCourse(v)
  }, [])

  const getAcademyStations = useCallback(() => {
    if(dispatchPeriodId == null && selectedYear == null) return;

    const args = (dispatchPeriodId && dispatchPeriodId > -1)
      ? [dispatchPeriodId, undefined]
      : [undefined, selectedYear];
    handleLoading(true);
    getAcademyStationListByPeriod(
      selectedAcademyID,
      ...args
    )
      .then(sl => {
        setStationsWithType(sl.stations)
        if (!mapRef.isSecure()) {
          return
        }
      })
      .catch(error => {
        throw new Error(
          `failed to get Academy Station List. (data: ${JSON.stringify(
            selectedAcademyID,
          )}, error: ${error})`,
        )
      }).finally(() =>{
        handleLoading(false);
      })
  }, [selectedAcademyID, dispatchPeriodId, selectedYear])

  const getAcademyLatLng = useCallback(() => {
    if (isNil(selectedAcademyID)) {
      return
    }

    getAcademyAddress(selectedAcademyID)
      .then(res => {
        setAcademyData(res)
      })
      .catch(error => {
        throw new Error(
          `failed to get Academy LatLng. (data: ${JSON.stringify(
            selectedAcademyID,
          )}, error: ${error})`,
        )
      })
  }, [selectedAcademyID])

  const setFixedCenterLatLng = useCallback(
    (center: { lat: number; lng: number }) => {
      setCenter({ lat: center.lat, lng: center.lng })
    },
    [setCenter],
  )

  // 정류장 추가 버튼
  const addStation = useCallback(() => {
    const data = {
      address: address.address,
      lat: markers[0].position.lat,
      lng: markers[0].position.lng,
      name: keyword,
      roadAddress: address.roadAddress,
      type: academyStationStatus ? 'ACADEMY' : 'NORMAL',
    }
    postAcademyStation(selectedAcademyID, data)
      .then(_ => {
        setKeyword('')
        setMarkers([])
        setAddress(null)
        setAcademyStationStatus(false)
        getAcademyStations()
        toast('station-add');
        setAddStationWarning(false)
        setAddNewStation(true)
      })
      .catch(error => {
        console.log(error)
        setError(error.response.data.message)
        setAddStationWarning(true)
      })
  }, [address, keyword, academyStationStatus, markers, setAddNewStation])

  // 주소, 장소 리스트에서 클릭 시 정류장 추가 모달 좌표 set
  const fixedMarker = useCallback((lat: number, lng: number) => {
    setMarkers([{ position: { lat: lat, lng: lng } }])
  }, [])

  useEffect(() => {
    if (!isEmptyArray(stationsWithType)) {
      setStations(stationsWithType.map(s => s.dybStationListDTO))
    }
  }, [stationsWithType])

  useEffect(() => {
    if (props.refresh) {
      getAcademyStations()
      props.setRefresh(false)
    }
  }, [props.refresh, props.setRefresh, selectedAcademyID])

  useEffect(() => {
    if (!isEmptyArray(markers)) {
      getAddress(markers[0].position.lat, markers[0].position.lng)
        .then(res => setAddress(res))
        .catch(error => {
          throw new Error(
            `failed to get LatLng. (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
      setFixedCenterLatLng({
        lat: markers[0].position.lat,
        lng: markers[0].position.lng,
      })
    }

    if (!isNil(position)) {
      getAddress(position.lat, position.lng)
        .then(res => setAddress(res))
        .catch(error => {
          throw new Error(
            `failed to get LatLng. (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
      setFixedCenterLatLng({
        lat: position.lat,
        lng: position.lng,
      })
    }
  }, [markers, position])

  useEffect(() => {
    if (stationRefresh) {
      getAcademyStations()
      setStationRefresh(false)
    }
  }, [stationRefresh, setStationRefresh])

  useEffect(() => {
    if (props.stationDeleteRefresh) {
      getAcademyStations()
      props.setStationDeleteRefresh(false)
      setIsShowStationDetail(false)
    }
  }, [
    props.stationDeleteRefresh,
    props.setStationDeleteRefresh,
    setIsShowStationDetail,
    selectedAcademyID,
  ])

  useEffect(() => {
    if (!isNil(academyData)) {
      setCenter({ lat: academyData?.lat, lng: academyData?.lng })
    }
  }, [academyData])

  useEffect(() => {
    setStation(selectedStation)
  }, [selectedStation])

  useEffect(() => {
    getAcademyLatLng()
  }, [selectedAcademyID])

  useEffect(() => {
    getAcademyStations()
  }, [dispatchPeriodId, selectedAcademyID])

  useEffect(() => {
    if (!isNil(selectedStation)) {
      const data = {
        academyID: selectedAcademyID,
        stationID: selectedStation?.id.toString(),
        dispatchPeriodId: dispatchPeriodId,
        year: selectedYear,
      }
      getStationInfo(data).then(res => {
        setCourseCnt(res.courses?.length)
        setStudentCnt(res.student?.length)
      })
    }
  }, [selectedStation])

  const onChangeCoordinate = useCallback(
    (cs: StationInfo[]) => {
      setRouteStationName(cs.map(s => ({ name: s.name })))
      setCoordinates(cs)
      if (isEmptyArray(cs)) {
        return
      }

      if (
        !isEmptyArray(cs) &&
        !isNil(selectedAcademyID) &&
        currentSelect === 'ROUTE'
      ) {
        const wayPoints = cs.slice(1, cs.length - 1)
        const wayPointsData = wayPoints.map(el => {
          return {
            lat: el.lat,
            lng: el.lng,
            stationId: el.stationId,
            stationName: el.name,
          }
        })

        const data = {
          academyID: selectedAcademyID,
          stations: {
            origin: {
              lat: cs[0].lat,
              lng: cs[0].lng,
              stationId: cs[0].stationId,
              stationName: cs[0].name,
            },
            destination: {
              lat: cs[cs.length - 1].lat,
              lng: cs[cs.length - 1].lng,
              stationId: cs[cs.length - 1].stationId,
              stationName: cs[cs.length - 1].name,
            },
            waypoints: cs?.length < 3 ? [] : wayPointsData,
          },
        }
        getCourseRoute(data).then(res => setCourseRouteData(res))
      }
    },
    [courseRouteData, selectedAcademyID, currentSelect, setRouteStationName],
  )

  // 모든 좌표 합치기
  const combinedVertexes = courseRouteData?.sections?.flatMap(
    (s: any) => s?.roads?.flatMap((r: { vertexes: any }) => r.vertexes),
  )

  const withoutFirstAndLast = combinedVertexes?.slice(
    2,
    combinedVertexes?.length - 2,
  )

  const vertexesAsObjects = withoutFirstAndLast?.reduce(
    (acc: { lat: any; lng: any }[], curr: any, i: number) => {
      if (i % 2 === 0) {
        acc?.push({ lng: curr, lat: withoutFirstAndLast[i + 1] })
      }
      return acc
    },
    [],
  )

  const filterStations = useMemo(() => {
    return stationsWithType.filter(
      station => {
        // 필터링 없이 전체
        if (stationFilter === 'ALL') return true;
        // 필터값은 사용안함 인데, station에 stationFilterType이 없는 경우 === 사용안함과 동일
        if (stationFilter === 'NOT_USED' && !station.stationFilterType) return true;
        // 필터값과 station의 stationFilterType이 같은 경우
        return (station.stationFilterType === stationFilter)
      }
    )
  }, [stationsWithType, stationFilter])

  const patchStationInfo = useCallback(
    (data: PutStationParams) => {
      putStation(data)
        .then(res => {
          if (res === 'OK') {
            getAcademyStations()
            setDragStation(null)
            setSelectedStation(null)
            setStationRefresh(true)
          }
        })
        .catch(error => {
          throw new Error(
            `failed to put station info. (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
    },
    [setDragStation],
  )

  const fetchDispatchPeriodYears = useCallback(() => {
    if (selectedAcademyID !== null) {
      getDispatchPeriodExtractYears(selectedAcademyID)
        .then(ys => {
          setYears(
            ys.length === 0 || ys === null || ys === undefined
              ? [CDate.now().toYear()]
              : ys,
          )
        })
        .catch(error => {
          throw new Error(
            `getDispatchPeriodExtractYears() failed. (error: ${error}, aid: ${selectedAcademyID}) `,
          )
        })
    }
  }, [setYears, selectedAcademyID])

  const onChangeStationFilter = useCallback((v: CStationType | 'ALL') => {
    setStationFilter(v)
  }, [setStationFilter])

  useEffect(() => {
    fetchDispatchPeriodYears()
  }, [selectedAcademyID])

  useEffect(() => {
    if (!isOpenAddCourse) {
      setCoordinates([])
      setCourseRouteData(null)
      setCenterStation([])
    }
    setSelectedStation(null)
  }, [isOpenAddCourse])

  useEffect(() => {
    if (currentSelect !== 'ROUTE') {
      setCoordinates([])
      setCourseRouteData(null)
    }
  }, [currentSelect, setCoordinates, setCourseRouteData])

  useEffect(() => {
    if (props.resetCourseRouteData) {
      setCourseRouteData(null)
      setRouteStationName([])
      props.setResetCourseRouteData(false)
    }
  }, [props.resetCourseRouteData, selectedAcademyID])

  useEffect(() => {
    if (isOpenAddCourse || isOpenModifyCourse) {
      setCenterStation(
        stations.filter(el => el.lat === center.lat && el.lng === center.lng),
      )
    }
  }, [stations, center])

  useEffect(() => {
    if (isOpen) {
      setMarkers([])
      setPosition(null)
    }
  }, [isOpen, setMarkers, setPosition])

  useEffect(() => {
    if (!isEmptyArray(markers)) {
      setSelectedStation(null)
      setIsOpen(false)
      setPosition(null)
    }
  }, [markers, setIsOpen, setSelectedStation, setPosition])

  useEffect(() => {
    if (!isNil(position)) {
      setIsOpen(false)
      setMarkers([])
    }
  }, [position, setIsOpen, setMarkers])

  useEffect(() => {
    if (
      (isOpenAddCourse && stationModalStatus) ||
      (isOpenModifyCourse && stationModalStatus)
    ) {
      setCenterStation([])
    }
  }, [isOpenAddCourse, isOpenModifyCourse, stationModalStatus])

  useEffect(() => {
    setError(null)
  }, [keyword])

  useEffect(() => {
    if (!isNil(selectedStation)) {
      setCenterStation([])
    }
  }, [selectedStation])

  useEffect(() => {
    if (!isEmptyArray(centerStation)) {
      setSelectedStation(null)
    }
  }, [centerStation])

  useEffect(() => {
    const routeArray = courseDetail?.course?.route.split(' - ') || []
    const routeObjects = routeArray.map(station => ({ name: station }))
    setRouteStationName(routeObjects)
  }, [courseDetail, setRouteStationName])

  const routeNameStation = useMemo(() => {
    return routeStationName.reduce((acc, item) => {
      acc[item.name] = true
      return acc
    }, {})
  }, [routeStationName])

  const onChangeDispatchPeriod = useCallback(
    (v: CDispatchPeriod) => {
      if (!isNil(v)) {
        setDispatchPeriodId(v.dispatchPeriod.id)
      }
    },
    [setDispatchPeriodId],
  )

  return (
    !isNil(academyData) &&
    !isNil(center) && (
      <Container style={{ width: innerWidth - 60, height: innerHeight - 40 }}>
        <Loading ref={loadingRef} />
        {currentSelect === 'ROUTE' && (
          <DispatchHeaderWrapper>
            <DispatchHeaderPicker
              years={years}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              onChangeDispatchPeriod={onChangeDispatchPeriod}
              reloadDispatchPeriod={props.reloadDispatchPeriod}
            />
          </DispatchHeaderWrapper>
        )}

        <MapIconInfo
          isShowStationName={isShowStationName}
          setIsShowStationName={setIsShowStationName}
        />
        <MapStationSummary
          onChageStationFilter={onChangeStationFilter}
          stationFilter={stationFilter}
          stationsWithType={stationsWithType} />
        <StationSideBar
          selectedStation={selectedStation}
          setSelectedStation={setSelectedStation}
          isShowStationDetail={isShowStationDetail}
          setIsShowStationDetail={setIsShowStationDetail}
          showDeleteModal={props.showDeleteModal}
          showModifyModal={props.showModifyModal}
          courseDetail={courseDetail}
          setCourseDetail={setCourseDetail}
          addCourseStatus={addCourseStatus}
          refresh={props.refresh}
          stationDeleteRefresh={props.stationDeleteRefresh}
          innerHeight={innerHeight}
          setFixedCenterLatLng={setFixedCenterLatLng}
          fixedMarker={fixedMarker}
          onChangeCoordinate={onChangeCoordinate}
          setDeleteStatus={props.setDeleteStatus}
          setModifyCourseStatus={props.setModifyCourseStatus}
          setCopyCourseStatus={props.setCopyCourseStatus}
          courseDelete={props.courseDelete}
          setCourseDelete={props.setCourseDelete}
          setCourseName={props.setCourseName}
          cancelCloseModifyModal={props.cancelCloseModifyModal}
          setCancelCloseModifyModal={props.setCancelCloseModifyModal}
          setDisableCirculation={props.setDisableCirculation}
          setContainStations={setContainStations}
          setIsOpenModifyCourse={setIsOpenModifyCourse}
          getCourseRoute={onChangeCoordinate}
          setCourseRouteData={setCourseRouteData}
          setCurrentSelect={setCurrentSelect}
          setOperationType={setOperationType}
          semesterID={semesterID}
          addNewStation={addNewStation}
          setAddNewStation={setAddNewStation}
          centerStation={centerStation}
          setConfirmModifyCourseModal={props.setConfirmModifyCourseModal}
          setConfirmAddCourseModal={props.setConfirmAddCourseModal}
          addCourseRef={addCourseRef}
          dispatchPeriodId={dispatchPeriodId}
          selectedYear={selectedYear}
          setResetCourseRouteData={props.setResetCourseRouteData}
          reloadOriginalCourse={props.reloadOriginalCourse}
          setReloadOriginalCourse={props.setReloadOriginalCourse}
        />
        <Map
          className="map"
          center={{
            lat: center?.lat,
            lng: center?.lng,
          }}
          isPanto={true}
          style={{
            width: '100%',
            height: '100%',
          }}
          level={4}
          onRightClick={(_target, mouseEvent) => {
            setMarkers([
              {
                position: {
                  lat: mouseEvent.latLng.getLat(),
                  lng: mouseEvent.latLng.getLng(),
                },
              },
            ])
          }}>
          {!isEmptyArray(markers) && (
            <MapMarker
              position={{
                lat: markers[0].position.lat,
                lng: markers[0].position.lng,
              }}
              image={{
                src: SelectedStationIcon,
                size: { width: 45, height: 45 },
                options: {
                  offset: {
                    x: 23,
                    y: 36,
                  },
                },
              }}>
              {/* 정류장 추가 */}
              {!isEmptyArray(markers) && (
                <CustomOverlayMap
                  position={{
                    lng: markers[0]?.position?.lng,
                    lat: markers[0]?.position?.lat,
                  }}
                  yAnchor={1}
                  xAnchor={0.5}
                  clickable={true}
                  zIndex={9999999}>
                  {AddStationPopUp(
                    academyStationStatus,
                    setAcademyStationStatus,
                    handleChangeKeyword,
                    keyword,
                    handleKeyDown,
                    addStationWarning,
                    error,
                    address,
                    setMarkers,
                    addStation,
                    setAddStationWarning,
                    setKeyword,
                  )}
                </CustomOverlayMap>
              )}
            </MapMarker>
          )}

          {!isNil(courseRouteData) && (
            <Polyline
              path={[vertexesAsObjects]}
              strokeWeight={7}
              strokeColor="#332a00"
              strokeOpacity={1}
              strokeStyle="solid"
            />
          )}

          {!isNil(courseRouteData) && (
            <Polyline
              path={[vertexesAsObjects]}
              strokeWeight={5}
              strokeColor="#FFD100"
              strokeOpacity={1}
              strokeStyle="solid"
            />
          )}

          {filterStations?.map(data => {
            const { dybStationListDTO: station, stationFilterType } = data;

            const lat: number = station?.lat
            const lng: number = station?.lng

            const position = markerPositions[station.id] || {
              lat: station.lat,
              lng: station.lng,
            } // Fallback to initial position if state is undefined

            const icon = (() => {
              const type = stationFilterType
              // 출발 상태
              const departureStatus = routeStationName[0]?.name === station.name
              // 도착 상태
              const arrivalStatus = routeStationName[routeStationName.length - 1]?.name === station.name
              // 경로(?)
              const isRouteStatus = !!(routeNameStation[station.name])
              // 드래그 상태
              const isDragStatus = dragStation?.id === station?.id
              // 학원 상태
              const isAcademyStatus = station.type === 'ACADEMY'
              // 선택된 정류장 상태
              const isSelectedStation = selectedStation?.name === station?.name || centerStation[0]?.name === station?.name

              // 출발 아이콘 상태
              if (departureStatus) return DepartureIcon
              // 도착 아이콘 상태
              if (arrivalStatus) return ArrivalIcon
              // 뭔지 모르겠음
              if (isRouteStatus) return SelectedStationNoneShadow
              // 드래그 아이콘 상태
              if (isDragStatus) return DragIcon
              // 학원 아이콘 상태
              if (isAcademyStatus) return AcademyIcon
              // 선택된 정류장 아이콘 상태
              if (isSelectedStation) return SelectedStationIcon

              if (!type) return StationIcon

              switch (type) {
                case 'NOT_USED':
                  return StationIcon
                case 'USED_IN_BOTH':
                  return StationUsedBothIcon
                case 'ONLY_IN_COURSE':
                  return StationUsedCourseIcon
                case 'ONLY_IN_DISPATCH':
                  return StationUsedDispatchIcon
                default:
                  return StationIcon
              }
            })()

            return (
              <div key={station.id}>
                <MapMarker
                  position={position}
                  ref={markerRef}
                  draggable={routeNameStation[station.name] ? false : true}
                  onClick={() => {
                    setSelectedStation(station)
                    setIsOpen(true)
                    setStationModalStatus(true)
                    setFixedCenterLatLng({ lat: lat, lng: lng })
                  }}
                  onDragStart={() => {
                    setDragStation({ id: station.id, name: station.name })
                    setSelectedStation(station)
                    setOriginalPosition({ lat: lat, lng: lng })
                  }}
                  onDragEnd={map => {
                    handleDragEnd(station.id, map)
                    setPosition({
                      lat: map.getPosition().getLat(),
                      lng: map.getPosition().getLng(),
                    })
                  }}
                  image={{
                    src: icon,
                    size: { width: 45, height: 45 },
                    options: {
                      offset: {
                        x: 23,
                        y: 30,
                      },
                    },
                  }}
                />
                {routeStationName
                  .slice(1, routeStationName.length - 1)
                  .some(s => s.name === station.name) && (
                    <CustomOverlayMap
                      position={{ lat: lat, lng: lng }}
                      yAnchor={-0.5}>
                      <RouteIdx
                        onClick={() => {
                          setMarkers([])
                          setSelectedStation(station)
                          setIsOpen(true)
                          setStationModalStatus(true)
                          setFixedCenterLatLng({ lat: lat, lng: lng })
                        }}>
                        {routeStationName.findIndex(
                          item => item.name === station.name,
                        ) === 0
                          ? ''
                          : routeStationName.findIndex(
                            item => item.name === station.name,
                          )}
                      </RouteIdx>
                    </CustomOverlayMap>
                  )}
                {/* 정류장명 보기 */}
                {isShowStationName && (
                  <CustomOverlayMap
                    position={{ lat: lat, lng: lng }}
                    yAnchor={-0.5}>
                    <BusStationName>{station.name}</BusStationName>
                  </CustomOverlayMap>
                )}

                {/* 지도 핀 클릭된 정류장 정보 */}
                {!isEmptyArray(centerStation) && isNil(selectedStation) && (
                  <CustomOverlayMap
                    position={{
                      lat: centerStation[0]?.lat,
                      lng: centerStation[0]?.lng,
                    }}
                    yAnchor={1.3}>
                    <SelectStationInfo>
                      <CloseImage onClick={() => setCenterStation([])}>
                        <CloseImg source={CloseIcon} />
                      </CloseImage>
                      <StationName>{centerStation[0]?.name}</StationName>
                      <RoadAddress>{centerStation[0]?.roadAddress}</RoadAddress>
                      <Address>{centerStation[0]?.address}</Address>
                    </SelectStationInfo>
                  </CustomOverlayMap>
                )}

                {/* 정류장 위치 수정 */}
                {isStationDragged &&
                  selectedStation?.name === station?.name && (
                    <CustomOverlayMap
                      position={{
                        lat: position.lat,
                        lng: position.lng,
                      }}
                      yAnchor={1.15}>
                      {ChangeStationPosition(
                        position,
                        setPosition,
                        station,
                        studentCnt,
                        courseCnt,
                        selectedAcademyID,
                        patchStationInfo,
                        setStationRefresh,
                        address,
                        setMarkers,
                        originalPosition,
                        setDragStation,
                        () => setSelectedStation(null),
                        setMarkerPositions,
                        setIsStationDragged,
                      )}
                    </CustomOverlayMap>
                  )}

                {/* 정류장 자세히보기 */}
                {isOpen && selectedStation?.name === station?.name && (
                  <CustomOverlayMap
                    position={{ lat: lat, lng: lng }}
                    yAnchor={1.2}
                    xAnchor={0.5}
                    clickable={true}>
                    {/* 노선 추가, 수정 시 정류장 선택할 때 */}
                    {(isOpenAddCourse &&
                      stationModalStatus &&
                      !isNil(operationType)) ||
                      (isOpenModifyCourse && stationModalStatus) ? (
                      <AddCourseStationPopUp
                        isOpenAddCourse={isOpenAddCourse}
                        isOpenModifyCourse={isOpenModifyCourse}
                        operationType={operationType}
                        setStationModalStatus={setStationModalStatus}
                        setSelectedStation={setSelectedStation}
                        station={station}
                      />
                    ) : // 노선 추가 시 등하원 구분 없을 때 정류장 선택 시
                      (isOpenAddCourse &&
                        stationModalStatus &&
                        isNil(operationType)) ||
                        (isOpenModifyCourse &&
                          stationModalStatus &&
                          isNil(operationType)) ? (
                        <SelectStationInfo>
                          <StationName>{selectedStation.name}</StationName>
                          <RoadAddress>{selectedStation.roadAddress}</RoadAddress>
                          <Address>{selectedStation.address}</Address>
                        </SelectStationInfo>
                      ) : // 노선 추가, 수정 열리지 않았을 때 정류장 선택하면
                        (!isOpenAddCourse && stationModalStatus) ||
                          (!isOpenModifyCourse && stationModalStatus) ? (
                          StationPopUp(
                            station,
                            setIsShowStationDetail,
                            closeModal,
                            studentCnt,
                            courseCnt,
                            setStationModalStatus,
                          )
                        ) : (
                          ''
                        )}
                  </CustomOverlayMap>
                )}
              </div>
            )
          })}
        </Map>
      </Container>
    )
  )
}

export default KakaoMap

/**
 *  노선 타입에 따라 정류장 추가 가능 여부 
 *  dispatchType = 등원 | 하원 | 순환
 * 
 *  marketType === 'NORMAL' | 'ACADEMY'
 *  case 'NORMAL' : 경유지, 도착지, 출발지
 *  case 'ACADEMY': 출발, 도착지 만 가능
 * 
 *  case '등원' :
 *  markerType === 'NORMAL' | 'ACADEMY'
 *  if marketType === 'NORMAL' : 경유지, 출발지
 *  if marketType === 'ACADEMY': 도착지만 가능
 * 
 *  case '하원' :
 *  markerType === 'NORMAL' | 'ACADEMY'
 *  if marketType === 'NORMAL' : 경유지, 도착지
 *  if marketType === 'ACADEMY': 출발지만 가능
 *  
 *  case '순환' :
 *  markerType === 'NORMAL' | 'ACADEMY'
 *  if marketType === 'NORMAL' : 경유지
 *  if marketType === 'ACADEMY': 출발지, 도착지
 *  specificCase: 출 ∙ 도착지에 해당하는 markerType ACADMEY는 동일할 수 없음
 * 
 */

type AddCourseStationPopUpProps = {
  isOpenAddCourse: Optional<boolean>
  isOpenModifyCourse: Optional<boolean>
  operationType: Optional<string>
  station: Optional<CStation>
  setStationModalStatus: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedStation: React.Dispatch<React.SetStateAction<any>>
}

function AddCourseStationPopUp({
  isOpenAddCourse,
  isOpenModifyCourse,
  operationType,
  setStationModalStatus,
  setSelectedStation,
  station
}: AddCourseStationPopUpProps) {
  const setStationAddInfo = useSetRecoilState(stationAddInfoAtom);
  const [addStationType, setAddStationType] = useState<string>('경유');

  const onAddStation = () => {
    if (station == null) return;
    setStationAddInfo({
      station,
      type: addStationType,
    });
    setStationModalStatus(false)
  }

  const disalbedMarkerType = (type: string) => {
    const config: Record<string, Record<string, Record<string, boolean>>> = {
      등원: {
        NORMAL: { '출발': false, '경유': false, '도착': true },
        ACADEMY: { '출발': true, '경유': true, '도착': false },
      },
      하원: {
        NORMAL: { '출발': true, '경유': false, '도착': false },
        ACADEMY: { '출발': false, '경유': true, '도착': true },
      },
      순환: {
        NORMAL: { '출발': true, '경유': false, '도착': true },
        ACADEMY: { '출발': false, '경유': true, '도착': false },
      },
    };

    return config[operationType]?.[station?.type]?.[type] ?? false;
  };

  useEffect(() => {
    const initialMarkerTypeConfig: Record<string, Record<string, string>> = {
      등원: {
        NORMAL: '경유',
        ACADEMY: '도착',
      },
      하원: {
        NORMAL: '경유',
        ACADEMY: '출발',
      },
      순환: {
        NORMAL: '경유',
        ACADEMY: '출발',
      },
    };

    const markerType = initialMarkerTypeConfig[operationType]?.[station?.type];
    if (markerType) {
      setAddStationType(markerType);
    }
  }, [operationType, station?.type]);

  return (
    <SelectStation>
      <StationInfoWrapper
        isOpenAddCourse={isOpenAddCourse}
        isOpenModifyCourse={isOpenModifyCourse}>
        <StationBorder>
          <StationName>{station?.name}</StationName>
          <StationRoadAddress>{station?.roadAddress}</StationRoadAddress>
          <StationAddress>{station?.address}</StationAddress>
          {operationType && <StationDivider />}
          {operationType && <StationTypeSelect>
            {['출발', '경유', '도착'].map((v) => {
              const isSelected = addStationType === v;
              const isDisabled = disalbedMarkerType(v);

              return (
                <StationTypeSelectItem
                  key={v}
                  onClick={() => {
                    if (isDisabled) return;
                    setAddStationType(v)
                  }}
                  isDisabled={isDisabled}
                  isSelected={isSelected}>
                  {isSelected && <img src={CheckBrownIcon} />}
                  <p>{v}</p>
                </StationTypeSelectItem>
              )
            }
            )}
          </StationTypeSelect>}
        </StationBorder>
      </StationInfoWrapper>
      <AddStationNoti>
        선택하신 정류장을 노선에 추가하시겠습니까?
        <ButtonWrapper>
          <CancelButton
            onClick={() => {
              setStationModalStatus(false)
              setSelectedStation(null)
            }}>
            취소
          </CancelButton>
          <AddButton
            onClick={onAddStation}>
            추가
          </AddButton>
        </ButtonWrapper>
      </AddStationNoti>
      <TriangleImg source={TriangleIcon} />
    </SelectStation>
  )
}

//TODO: 드래그로 옮긴 후 취소 안하고 다른거 누르면 남아있음

function ChangeStationPosition(
  position: Coordinate,
  setPosition: React.Dispatch<React.SetStateAction<Optional<Coordinate>>>,
  station: CStation,
  studentCnt: number,
  courseCnt: number,
  selectedAcademyID: string,
  patchStationInfo: (data: PutStationParams) => void,
  setStationRefresh: React.Dispatch<React.SetStateAction<boolean>>,
  address?: AddressProps,
  setMarkers?: any,
  originalPosition?: Coordinate, // 원래 위치
  setDragStation?: React.Dispatch<React.SetStateAction<any>>, // setDragStation 함수
  setSelectedStation?: React.Dispatch<React.SetStateAction<any>>,
  setMarkerPositions?: React.Dispatch<React.SetStateAction<any>>,
  setIsStationDragged?: React.Dispatch<React.SetStateAction<any>>,
) {
  const data = {
    academyID: selectedAcademyID,
    stationID: String(station.id),
    address: address?.address,
    roadAddress: address?.roadAddress,
    name: station.name,
    type: station.type,
    lat: position.lat,
    lng: position.lng,
  }

  const resetStationState = () => {
    setIsStationDragged(false)

    if (originalPosition) {
      setPosition(originalPosition)

      // Update marker positions
      setMarkerPositions((prev: any) => ({
        ...prev,
        [station.id]: originalPosition, // Reset the marker to its original position
      }))
    } else {
      setPosition(null)
    }

    if (originalPosition) {
      setPosition(originalPosition)
    } else {
      setPosition(null)
    }
    setMarkers([])
    //WONTFIX: 일단 빼봄.. 왜 취소하면 또 부르는지 모르겠음
    // setStationRefresh(true)
    if (setDragStation) {
      setDragStation(null)
    }
    if (setSelectedStation) {
      setSelectedStation(null)
    }
  }

  return (
    <AddStationModal>
      <AddressContainer>
        <SelectAddressWrapper style={{ height: '13.6rem' }}>
          <Title>정류장을 여기로 이동하시겠습니까?</Title>
          <StationInfoWrapper
            style={{
              width: '29.6rem',
              borderRadius: '0.8rem',
              padding: '1.2rem',
              height: '8.8rem',
            }}>
            <StationName>{station.name}</StationName>
            <StationRoadAddress>
              {isEmptyString(address?.roadAddress) ? '-' : address?.roadAddress}
            </StationRoadAddress>
            <StationAddress>{address?.address}</StationAddress>
          </StationInfoWrapper>
        </SelectAddressWrapper>
        <AddressBottom>
          <AcademyStatusWrapper>
            <StudentRoute>
              <IconImg source={StudentIcon} /> <span>{studentCnt}명</span>
              <IconImg source={RouteIcon} /> <span>{courseCnt}개</span>
            </StudentRoute>
          </AcademyStatusWrapper>
          <ButtonWrapper>
            <CancelButton
              onClick={() => {
                resetStationState()
              }}>
              취소
            </CancelButton>
            <AddButton
              onClick={() => {
                setMarkers([])
                patchStationInfo(data)
                if (setDragStation) {
                  setDragStation(null)
                }
                if (setSelectedStation) {
                  setSelectedStation(null)
                }
                setIsStationDragged(false)
              }}>
              이동
            </AddButton>
          </ButtonWrapper>
        </AddressBottom>
        <TriangleImg source={TriangleIcon} />
      </AddressContainer>
    </AddStationModal>
  )
}

function AddStationPopUp(
  academyStationStatus: boolean,
  setAcademyStationStatus: React.Dispatch<React.SetStateAction<boolean>>,
  handleChangeKeyword: any,
  keyword: string,
  handleKeyDown: any,
  addStationWarning: boolean,
  error: Optional<string>,
  address?: AddressProps,
  setMarkers?: any,
  addStation?: () => void,
  setAddStationWarning?: React.Dispatch<React.SetStateAction<boolean>>,
  setKeyword?: React.Dispatch<React.SetStateAction<string>>,
) {
  const cancelAddStation = () => {
    setMarkers([])
    setAddStationWarning(false)
  }

  return (
    <AddStationModal>
      <AddressContainer>
        <SelectAddressWrapper addStationWarning={addStationWarning}>
          <Title>선택하신 위치를 정류장으로 추가하시겠습니까?</Title>
          <InputAddress addStationWarning={addStationWarning}>
            <InputWrapper addStationWarning={addStationWarning}>
              <Input
                placeholder="정류장명을 입력해 주세요."
                onKeyDown={handleKeyDown}
                onChange={handleChangeKeyword}
                value={keyword}
                addStationWarning={error?.includes('R101')}
              />
              {keyword?.length > 0 && (
                <DeleteButtonWrapper onClick={() => setKeyword('')}>
                  <DeleteButton source={DeleteIcon} />
                </DeleteButtonWrapper>
              )}
            </InputWrapper>
            {error?.includes('R101') && (
              <AddStationWarning>
                이미 등록되어 있는 정류장명입니다.
              </AddStationWarning>
            )}
            {error?.includes('could not execute statement') && (
              <AddStationWarning>
                정류장명의 길이 한계를 초과하였습니다.
              </AddStationWarning>
            )}
            <RoadAddress>
              {address?.roadAddress === '' ? '-' : address?.roadAddress}
            </RoadAddress>
            <Address>
              {address?.address === '' ? '-' : address?.address}
            </Address>
          </InputAddress>
        </SelectAddressWrapper>
        <AddressBottom>
          <AcademyStatusWrapper>
            <AcademyStatusButton
              onClick={() => setAcademyStationStatus(!academyStationStatus)}>
              <CheckImg
                source={academyStationStatus ? CheckIcon : UncheckIcon}
              />
            </AcademyStatusButton>
            학원 정류장으로 지정
          </AcademyStatusWrapper>
          <ButtonWrapper>
            <CancelButton onClick={cancelAddStation}>취소</CancelButton>
            <AddButton onClick={addStation}>추가</AddButton>
          </ButtonWrapper>
        </AddressBottom>
        <TriangleImg source={TriangleIcon} />
      </AddressContainer>
      <SelectPlaceImg source={SelectedStationNoneShadow} />
    </AddStationModal>
  )
}

type StationPopUpProps = {
  station: CStation
  setIsShowStationDetail: React.Dispatch<React.SetStateAction<boolean>>
  closeModal: () => void
  studentCnt: number
  courseCnt: number
  setStationModalStatus: React.Dispatch<React.SetStateAction<boolean>>
}

function StationPopUp(
  station: CStation,
  setIsShowStationDetail: React.Dispatch<React.SetStateAction<boolean>>,
  closeModal: () => void,
  studentCnt: number,
  courseCnt: number,
  setStationModalStatus: React.Dispatch<React.SetStateAction<boolean>>,
) {
  return (
    <SelectStation>
      <StationInfoWrapper>
        <ImgWrapper onClick={closeModal}>
          <CloseImg source={CloseIcon} />
        </ImgWrapper>
        <StationName>{station.name}</StationName>
        <StationRoadAddress>{station?.roadAddress}</StationRoadAddress>
        <StationAddress>{station?.address}</StationAddress>
      </StationInfoWrapper>
      <StudentRouteWrapper>
        {station.type === 'ACADEMY' ? (
          <StationType>
            <AcademyIconImg source={AcademyIcon2} /> <span>학원정류장</span>
          </StationType>
        ) : (
          <StudentRoute>
            <IconImg source={StudentIcon} /> <span>{studentCnt}명</span>
            <IconImg source={RouteIcon} /> <span>{courseCnt}개</span>
          </StudentRoute>
        )}
        <DetailButton
          onClick={() => {
            setIsShowStationDetail(true)
            // setStationModalStatus(false)
          }}>
          자세히 보기
          <RightArrowImg source={RightArrow} />
        </DetailButton>
      </StudentRouteWrapper>
      <TriangleImg source={TriangleIcon} />
    </SelectStation>
  )
}

type MapInfoProps = {
  isShowStationName: boolean
  setIsShowStationName: React.Dispatch<React.SetStateAction<boolean>>
}

function MapIconInfo(props: MapInfoProps) {
  return (
    <MapInfo className="map-info">
      <Ul>
        <Li
          onClick={() => props.setIsShowStationName(!props.isShowStationName)}>
          <CheckImg
            source={props.isShowStationName ? CheckIcon : UncheckIcon}
          />
          정류장명 보기
        </Li>
      </Ul>
    </MapInfo>
  )
}

type MapStationSummaryProps = {
  stationsWithType: CStationWithType[];
  stationFilter: CStationType | 'ALL';
  onChageStationFilter: (type: CStationType | 'ALL') => void;
}

function MapStationSummary({
  stationsWithType,
  onChageStationFilter,
  stationFilter
}: MapStationSummaryProps) {

  const gruopedStationsByType = stationsWithType.reduce((acc, station) => {
    const { stationFilterType: type } = station;

    if (!type) {
      acc['NOT_USED'].push(station)
      return acc;
    }

    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(station);
    return acc;
  }, {
    USED_IN_BOTH: [],
    ONLY_IN_COURSE: [],
    ONLY_IN_DISPATCH: [],
    NOT_USED: [],
  } as Record<CStationType, CStationWithType[]>);

  const onChange = (type: CStationType | 'ALL') => {
    if (type === stationFilter) return;
    onChageStationFilter(type)
  }

  return (
    <StationSummarySection>
      <StationSummary onClick={() => onChange('ALL')} selected={stationFilter === 'ALL'}>
        <StationSummaryChip type="ALL">전체</StationSummaryChip>
        <StationSummaryText>{stationsWithType.length}</StationSummaryText>
      </StationSummary>
      {Object.entries(gruopedStationsByType).map(([type, stations]) => {
        return (
          <StationSummary
            key={type}
            onClick={() => onChange(type as CStationType)}
            selected={stationFilter === type as CStationType}>
            <StationSummaryChip type={type}>
              {CStationTypeLabels[type as CStationType]}
            </StationSummaryChip>
            <StationSummaryText>{stations.length}</StationSummaryText>
          </StationSummary>
        )
      })}
    </StationSummarySection>
  )
}