import { AxiosEnum } from '../../enum/AxiosEnum'
import axios from 'axios'
import { API } from '../../Env'
import {
  CCourseDetail,
  CStation,
  CStationDetail,
  CStationVector,
} from '../../model/Station'
import { StationInfo } from '../../component/stationManagement/stationSideBar/StationSideBarCourseSetting'

export async function getAcademyStationList(
  academyID: string,
  lat: string,
  lng: string,
) {
  const url = `${API}/${academyID}/stations/geo`

  const params = {
    lat: lat,
    lng: lng,
    distance: 200000,
  }

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function getAcademyStationListByPeriod(
  academyID: string,
  dispatchPeriodId?: number,
  selectedYear?: number,
) {
  const url = `${API}/${academyID}/stations/filter`

  const params = {
    ...((dispatchPeriodId && dispatchPeriodId > 0) && { dispatchPeriodId: dispatchPeriodId }),
    ...(selectedYear && { year: selectedYear }),
  }

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function getAddress(lat: number, lng: number) {
  const url = `${API}/search/geo`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      lat: lat,
      lng: lng,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function getAcademyAddress(academyID: string) {
  const url = `${API}/${academyID}`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export type PostStationParams = {
  address: string
  lat: number
  lng: number
  name: string
  roadAddress: string
  type: string
}

export async function postAcademyStation(
  academyID: string,
  data: PostStationParams,
) {
  const url = `${API}/${academyID}/stations`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      address: data.address,
      lat: data.lat,
      lng: data.lng,
      name: data.name,
      roadAddress: data.roadAddress,
      type: data.type,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export type StationInfoParams = {
  academyID: string
  stationID: string
  dispatchPeriodId: number
  year: number
}
export async function getStationInfo(data: StationInfoParams) {
  const url = `${API}/${data.academyID}/stations/${data.stationID}`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      dispatchPeriodId:
        data.dispatchPeriodId === -1 ? null : data.dispatchPeriodId,
      year: data.year,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export type DeleteStationParams = {
  academyID: string
  stationID: string
}

export async function deleteStation(data: DeleteStationParams) {
  const url = `${API}/${data.academyID}/stations/${data.stationID}`

  const config = {
    method: AxiosEnum.DELETE.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export type PutStationParams = {
  academyID: string
  stationID: string
  address: string
  name: string
  roadAddress: string
  type: string
  lat: number
  lng: number
}

export async function putStation(data: PutStationParams) {
  const url = `${API}/${data.academyID}/stations/${data.stationID}`

  const config = {
    method: AxiosEnum.PUT.value,
    url: url,
    data: {
      address: data.address,
      name: data.name,
      roadAddress: data.roadAddress,
      type: data.type,
      lat: data.lat,
      lng: data.lng,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export type GetSearchStationData = {
  academyID: string
  keyword: string
  page: number
  size: number
  dispatchPeriodId?: number
  year?: number
}

export async function getSearchStation(
  data: GetSearchStationData,
): Promise<CStationVector> {
  const url = `${API}/${data.academyID}/search/stations`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      keyword: data.keyword,
      page: data.page,
      size: data.size,
    },
  }

  const axiosResponse = await axios(config)

  return CStationVector.create(axiosResponse.data.data)
}

export async function getSearchStationDetail(
  academyID: string,
  id: string,
  dispatchPeriodId: number,
  year: number,
) {
  const url = `${API}/${academyID}/stations/${id}`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      dispatchPeriodId: dispatchPeriodId,
      year: year,
    },
  }

  const axiosResponse = await axios(config)

  return CStationDetail.create(axiosResponse.data.data)
}

export async function getSearchAddress(data: GetSearchStationData) {
  const url = `${API}/search/address`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      keyword: data.keyword,
      page: data.page,
      size: data.size,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function getCourses(data: GetSearchStationData) {
  const url = `${API}/${data.academyID}/courses/dispatchPeriod`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      page: data.page,
      size: data.size,
      dispatchPeriodId:
        data.dispatchPeriodId === -1 ? null : data.dispatchPeriodId,
      searchValue: data.keyword,
      year: data.year,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function getSearchCourses(data: GetSearchStationData) {
  const url = `${API}/${data.academyID}/search/courses`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      keyword: data.keyword,
      page: data.page,
      size: data.size,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function getSearchCourseDetail(
  academyID: string,
  id: string,
  // semesterId?: number,
  dispatchPeriodId?: number,
  year?: number,
) {
  const url = `${API}/${academyID}/courses/${id}`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      // semesterId: semesterId,
      dispatchPeriodId: dispatchPeriodId === -1 ? null : dispatchPeriodId,
      year: year,
    },
  }

  const axiosResponse = await axios(config)

  return CCourseDetail.create(axiosResponse.data.data)
}

export async function getSearchPlace(data: GetSearchStationData) {
  const url = `${API}/search/place`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      keyword: data.keyword,
      page: data.page,
      size: data.size,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function getStationsWithAcademyCategory(academyID: string) {
  const url = `${API}/${academyID}/stations/academy-category`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data.map((el: any) => CStation.create(el))
}

type PostCourseParams = {
  academyID: string
  dispatchType: string
  name: string
  route: StationInfo[]
}

export async function postCourses(data: PostCourseParams) {
  const url = `${API}/${data.academyID}/courses`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      dispatchType: data.dispatchType,
      name: data.name,
      route: data.route,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data
}

type PutCourseParams = {
  academyID: string
  dispatchType: string
  courseID: string
  name: string
  route: StationInfo[]
}

export async function putCourses(data: PutCourseParams) {
  const url = `${API}/${data.academyID}/courses/${data.courseID}`

  const config = {
    method: AxiosEnum.PUT.value,
    url: url,
    data: {
      dispatchType: data.dispatchType,
      name: data.name,
      route: data.route,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

type CourseInfoParams = {
  academyID: string
  courseID: string
}
export async function deleteCourse(data: CourseInfoParams) {
  const url = `${API}/${data.academyID}/courses/${data.courseID}`

  const config = {
    method: AxiosEnum.DELETE.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

type CourseRouteParams = {
  academyID: string
  stations: any
}

export async function getCourseRoute(data: CourseRouteParams) {
  const url = `${API}/${data.academyID}/courses/route`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: data.stations,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}
