import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import ArrivalIcon from '../../../asset/image/arrival_station_black.svg'
import { OperationTypeEnum } from '../../../enum/OperationTypeEnum'
import Picker, { toPickerOptions } from '../../input/Picker'
import StationSideBarSelect, { StationSelectRef } from './StationSideBarSelect'
import PinIcon from '../../../asset/image/pin.svg'
import DisabledPinIcon from '../../../asset/image/disabled_pin.svg'
import YellowPinIcon from '../../../asset/image/pin_yellow.svg'
import styled from 'styled-components'
import { flexRow } from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import { CCourseDetail, CStation } from '../../../model/Station'
import useSecureRef from '../../../hook/useSecureRef'
import { Optional } from '../../../type/Common'
import { isEmptyArray, isNil } from '../../../util/ValidationUtil'
import { StationInfo } from './StationSideBarCourseSetting'
import { useRecoilState } from 'recoil'
import { stationAddInfoAtom } from '../atom/station-management'

type StationSideBarArrivalProps = {
  operationType: OperationTypeEnum
  stations: CStation[]
  setFixedCenterLatLng(center: { lat: number; lng: number }): void
  onChangeArrival(s: CStation): void
  modifyType: Optional<number> //배차길이 (수정이냐 복제냐 구분)
  courseDetail?: CCourseDetail
  addNewCourse: boolean
  selectedDepartureStation: CStation
  selectedIdx: Optional<number>
  setSelectedIdx: React.Dispatch<React.SetStateAction<number>>
  checkedStation: Optional<CStation>
  handleSelectedStation: React.Dispatch<
    React.SetStateAction<Optional<CStation>>
  >
  centerStation: CStation[]
  setSelectedArrivalStation: React.Dispatch<React.SetStateAction<CStation>>
}
export type StationSideBarArrivalRef = {
  getStationInfo(): StationInfo
}

function StationSideBarArrivalBase(
  props: StationSideBarArrivalProps,
  ref: ForwardedRef<StationSideBarArrivalRef>,
): ReactElement {
  const [stationAddInfo, setStationAddInfo] = useRecoilState(stationAddInfoAtom);
  const selectRef = useSecureRef<StationSelectRef>(
    '[StationSideBarArrival.tsx] selectRef',
  )

  const [selectedStation, setSelectedStation] = useState<Optional<CStation>>(null)
  const [disabledIdx, setDisabledIdx] = useState<Optional<number>>(null)

  useEffect(() => {
    if (props.selectedDepartureStation) {
      const selectDepartureIdx = academyOptions?.findIndex(
        academy => academy.value.id === props.selectedDepartureStation.id,
      )
      setDisabledIdx(selectDepartureIdx)
    }
  }, [props.selectedDepartureStation])

  const handleCenterLatLng = useCallback(() => {
    if (isNil(selectedStation?.name)) {
      return
    }

    props.setSelectedIdx(100)

    props.setFixedCenterLatLng({
      lat: selectedStation.lat,
      lng: selectedStation.lng,
    })
    props.handleSelectedStation(null)
  }, [props.setFixedCenterLatLng, selectedStation])

  const academyOptions = useMemo(() => {
    return toPickerOptions(
      props.stations,
      v => <div style={{ fontSize: 13, paddingLeft: 6 }}>{v.name}</div>,
      v => <div style={{ fontSize: 13 }}>{v.name}</div>,
    )
  }, [props.stations])

  const onSelectStation = useCallback(
    (s: CStation) => {
      props.setSelectedArrivalStation(s)
      setSelectedStation(s)
      props.onChangeArrival({
        ...s, // s의 모든 속성을 복사
        spendTime: 0, // spendTime을 0으로 설정
      })
    },
    [setSelectedStation, props.onChangeArrival],
  )

  useEffect(() => {
    const station =
      props?.courseDetail?.course.stations[
      props?.courseDetail?.course.stations?.length - 1
      ]

    setSelectedStation({
      type: station?.type,
      id: station?.id,
      name: station?.name,
      roadAddress: station?.roadAddress,
      address: station?.address,
      lat: station?.lat,
      lng: station?.lng,
    })
  }, [props?.courseDetail?.course])

  useEffect(() => {
    if (stationAddInfo && stationAddInfo.type === '도착') {
      if(props?.selectedDepartureStation?.id === stationAddInfo?.station?.id) {
        return alert('출발 학원 정류장과 도착 학원 정류장은 같을 수 없습니다.')
      }

      onSelectStation(stationAddInfo.station)
      setStationAddInfo(null)
    }
  }, [stationAddInfo, props.selectedDepartureStation])

  useEffect(() => {
    if(props.modifyType != null) return;
    setSelectedStation(null)
    setStationAddInfo(null)
  }, [props.operationType, props.modifyType])

  const selectedAcademyStation = (() => {
    const lastStationName =
      props?.courseDetail?.course.stations[
      props?.courseDetail?.course.stations?.length - 1
      ]
    if (!props.modifyType) {
      if (!selectedStation) return null
      return academyOptions.filter(el => el.value?.name === selectedStation?.name)
    }
    return academyOptions.filter(el => el.value?.name === lastStationName?.name)
  })()

  const enteredStation = (() => {
    if (props.addNewCourse) {
      if (selectedStation?.id) return selectedStation
      return null
    }

    if (props.courseDetail?.course.stations.length === 1) {
      return null
    }

    return props.courseDetail?.course.stations[
      props.courseDetail?.course.stations.length - 1
    ]
  })()

  const SelectInputComponent = useMemo(() => {
    if (props.operationType === OperationTypeEnum.OUTBOUND) {
      return (
        <StationSideBarSelect
          type="arrival"
          ref={selectRef.ref}
          onChange={onSelectStation}
          selectedStation={enteredStation}
        />
      )
    }

    if (props.stations.length === 1) {
      return (
        <AcademyStationInput
          placeholder={props.stations[0].name}
          readOnly={true}
        />
      )
    }

    return (
      <>
        {props.addNewCourse && (
          <Picker
            options={academyOptions}
            placeholder={'학원 정류장 선택'}
            minWidth={190}
            height={30}
            onChange={onSelectStation}
            disabledIdx={disabledIdx}
            defaultIdx={selectedAcademyStation ? selectedAcademyStation[0]?.idx : null}
          />
        )}
        {!props.addNewCourse && !isEmptyArray(selectedAcademyStation) && (
          <Picker
            options={academyOptions}
            placeholder={'학원 정류장 선택'}
            minWidth={190}
            height={30}
            onChange={onSelectStation}
            disabledIdx={disabledIdx}
            defaultIdx={
              !isNil(props?.modifyType) ? selectedAcademyStation[0]?.idx : null
            }
          />
        )}
        {!props.addNewCourse && isEmptyArray(selectedAcademyStation) && (
          <Picker
            options={academyOptions}
            placeholder={'학원 정류장 선택'}
            minWidth={190}
            height={30}
            onChange={onSelectStation}
          />
        )}
      </>
    )
  }, [
    props.operationType,
    props.stations,
    academyOptions,
    onSelectStation,
    props.courseDetail,
    disabledIdx,
    selectedAcademyStation,
  ])

  useImperativeHandle(
    ref,
    () => ({
      getStationInfo(): Optional<StationInfo> {
        if (isNil(selectedStation)) {
          return null
        }
        return {
          stationId: selectedStation.id,
          spendTime: 0,
          lat: selectedStation.lat,
          lng: selectedStation.lng,
          name: selectedStation.name,
        }
      },
    }),
    [selectedStation],
  )
  return (
    <ArrivalStation>
      <DefaultImg source={ArrivalIcon} />
      {SelectInputComponent}
      <MapIcon
        onClick={handleCenterLatLng}
        selectedStation={selectedStation}
        src={
          isNil(selectedStation?.name)
            ? DisabledPinIcon
            : isEmptyArray(props.centerStation)
              ? PinIcon
              : props.selectedIdx === 100 && isNil(props.checkedStation)
                ? YellowPinIcon
                : PinIcon
        }
      />
    </ArrivalStation>
  )
}

const StationSideBarArrival = forwardRef(StationSideBarArrivalBase)
export default StationSideBarArrival

const DefaultImg = styled(SVGImage)`
  width: 2.4rem;
  height: 2.4rem;
`

const ArrivalStation = styled.div`
  position: relative;
  ${flexRow};
  height: 3rem;
  column-gap: 0.2rem;
  align-items: center;
`

const StationInput = styled.input`
  width: 18rem;
  height: 3rem;
  padding: 0.6rem 0.8rem;
  border-radius: 0.6rem;
  border: 0.1rem solid #ebebeb;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 300;
  color: #000;
  cursor: pointer;

  ::placeholder {
    color: #000;
  }
`

const AcademyStationInput = styled(StationInput)`
  background: #f5f5f5;
  font-weight: 500;
  color: #999;
`

type PinProps = {
  selectedStation?: Optional<CStation>
}

const MapIcon = styled.img<PinProps>`
  width: 1.8rem;
  height: 1.8rem;
  cursor: ${props =>
    isNil(props.selectedStation?.name) ? 'not-allowed' : 'pointer'};
`
