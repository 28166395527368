import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'
import DownArrow from '../../../asset/image/station_down_arrow.svg'
import styled from 'styled-components'
import {flexRow} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import {isEmptyString, isNil} from '../../../util/ValidationUtil'
import {CStation} from '../../../model/Station'

type StationSideBarTimeInputContainerProps = {
  selectedStation: CStation
  addStationInfo(): void
  onChange(st: number): void
  value?: number
}
export type StationSideBarTimeInputContainerRef = {}

function StationSideBarTimeInputContainerBase(
  props: StationSideBarTimeInputContainerProps,
  _: ForwardedRef<StationSideBarTimeInputContainerRef>,
): ReactElement {
  const [value, setValue] = useState<string>('')
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const t = e.target.value
      const st = Number(t)
      if (isEmptyString(t) || isNaN(st) || isNil(st)) {
        setValue('')
        props.onChange(null)
        return
      }

      setValue(t)
      props.onChange(st)
    },
    [props.onChange, setValue],
  )

  useEffect(() => {
    if (isNil(props.value)) {
      setValue(String(''))
      return
    }
    setValue(String(props.value))
  }, [props.value, setValue])

  return (
    <TimeStationWrapper>
      <TimeWrapper>
        <RectangleImg source={DownArrow} />
        <TimeInputWrapper>
          <TimeInput
            onChange={onChange}
            placeholder="--"
            maxLength={2}
            value={value}
            disabled={props.selectedStation ? false : true}
            title={'정류장 선택 후 입력 가능'}
            tabIndex={1}
          />
          <TimeText>분 소요</TimeText>
        </TimeInputWrapper>
      </TimeWrapper>
    </TimeStationWrapper>
  )
}

const StationSideBarTimeInputContainer = forwardRef(
  StationSideBarTimeInputContainerBase,
)
export default StationSideBarTimeInputContainer
const TimeStationWrapper = styled.div`
  ${flexRow};
  align-items: center;
  padding-left: 2.3rem;
  justify-content: space-between;
`

const TimeWrapper = styled.div`
  ${flexRow};
  column-gap: 0.5rem;
  align-items: center;
`

const TimeInputWrapper = styled.div`
  border-radius: 0.6rem;
  border: 0.1rem solid #ebebeb;
  background: #fff;
  padding: 0.4rem 0.8rem;
  ${flexRow};
`

const TimeInput = styled.input`
  border: none;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  width: 2rem;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const TimeText = styled.div`
  font-size: 1rem;
  line-height: 1.8rem;
  font-weight: 400;
  color: #665300;
`

const RectangleImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const StationInput = styled.input`
  width: 18rem;
  height: 3rem;
  padding: 0.6rem 0.8rem;
  border-radius: 0.6rem;
  border: 0.1rem solid #ebebeb;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 300;
  color: #000;
  cursor: pointer;

  ::placeholder {
    color: #000;
  }
`
