import styled from 'styled-components'
import { flexColumn, flexRow, Icon } from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'


export const Container = styled.div`
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.15);
  position: relative;
  border-radius: 16px;
  .map {
    border-radius: 16px;
  }
`

export const DispatchHeaderWrapper = styled.div`
  z-index: 1002;
  max-width: calc(100% - 56px);
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 2rem;
  right: 15rem;
`

export const BusStationName = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 0.2rem 0.4rem;
  text-align: center;
  border-radius: 0.4rem;
`

export const SelectStation = styled.div`
  ${flexColumn};
  align-items: center;
  justify-content: center;
`

type StationInfoProps = {
  isOpenAddCourse?: boolean
  isOpenModifyCourse?: boolean
}

export const StationInfoWrapper = styled.div<StationInfoProps>`
  width: ${props =>
    props.isOpenAddCourse || props.isOpenModifyCourse ? '32rem' : '24rem'};
  border-radius: 0.8rem 0.8rem 0 0;
  padding: 0.8rem 1.2rem;
  background: #fff;
  position: relative;
  border: 0.1rem solid #f5f5f5;
`

export const StationBorder = styled.div`
  border: 0.1rem solid #ebebeb;
  border-radius: 0.8rem;
  padding: 0.8rem;
`

export const StationName = styled.div`
  width: 19.5rem;
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 2.2rem;
  margin-bottom: 0.6rem;
  white-space: normal;
`

export const StationAddress = styled.div`
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #666;
`

export const StationRoadAddress = styled(StationAddress)`
  color: #000;
  margin-bottom: 0.4rem;
`

export const ImgWrapper = styled.div`
  position: absolute;
  top: 0.8rem;
  right: 1.2rem;
`

export const CloseImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

export const StudentRouteWrapper = styled.div`
  ${flexRow};
  justify-content: space-between;
  padding: 0.8rem 1.2rem;
  background: #f5f5f5;
  border-radius: 0 0 0.8rem 0.8rem;
  width: 24rem;
`

export const AddStationNoti = styled.div`
  ${flexRow}
  justify-content: space-between;
  padding: 0.8rem 1.2rem;
  background: #f5f5f5;
  border-radius: 0 0 0.8rem 0.8rem;
  width: 32rem;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 150%;
`

export const AddStationButton = styled.div`
  border-radius: 1.6rem;
  background: #ffd100;
  padding: 0.2rem 0.8rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
`

export const StudentRoute = styled.div`
  ${flexRow};
  align-items: center;

  > span {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.4rem;
    color: #666;
    margin-right: 0.8rem;
  }
`

export const IconImg = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.2rem;
`

export const DetailButton = styled.button`
  border: none;
  border-radius: 1.6rem;
  background: #333;
  padding: 0.2rem 0.4rem 0.2rem 0.8rem;
  color: #fff1b3;
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
`

export const RightArrowImg = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 0.2rem;
`

export const MapInfo = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  top: 20px;
  right: 16px;
  z-index: 1002;
  max-width: calc(100% - 56px);
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  cursor: default;

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 4px 10px;

      ${Icon} {
        margin-right: 4px;
      }
    }
  }
`

export const StationSummarySection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background: #fff;
  border-radius: 10px;
  padding: 8px;
  position: absolute;
  bottom: 20px;
  right: 16px;
  z-index: 1002;
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
`;

export const StationSummary = styled.div<{selected: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px;
  cursor: pointer;
  user-select: none;

  ${({selected}) => selected && `
    background: #ffef96;
    border-radius: 8px;
  `}
`;

export const StationSummaryChip = styled.div<{ type: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 10px;
  background-color: #f5f5f5;
  color: #666;
  font-size: 12px;
  font-weight: 500;
  ${({ type }) => {
    if (type === 'ALL') {
      return `
        background-color: #11c700;
        color: #fff;
      `;
    }
    if (type === 'USED_IN_BOTH') {
      return `
        background-color: #9200e7;
        color: #fff;
      `;
    } else if (type === 'ONLY_IN_COURSE') {
      return `
        background-color: #0062ff;
        color: #fff;
      `;
    } else if (type === 'ONLY_IN_DISPATCH') {
      return `
        background-color: #ff3a3a;
        color: #fff;
      `;
    }
  }}
`;

export const StationSummaryText = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const Ul = styled.ul``
export const Li = styled.li`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.4rem;
  color: #665300;
`

export const CheckImg = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.6rem;
  margin-left: 0.2rem;
`

export const RouteIdx = styled.div`
  position: absolute;
  top: -4.8rem;
  left: -0.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 500%;
  color: #665300;
  height: 4rem;
  width: 4rem;
`

export const AddStationModal = styled.div`
  ${flexColumn};
  align-items: center;
  justify-content: center;
  cursor: default;
`

export const TriangleImg = styled(SVGImage)`
  width: 1.4rem;
  height: 0.7rem;
`

export const AddressContainer = styled.div`
  ${flexColumn};
  justify-content: center;
  align-items: center;
`

export const SelectAddressWrapper = styled.div<InputProps>`
  width: 32rem;
  height: ${props => (props.addStationWarning ? '16.8rem' : '14.8rem')};
  padding: 0.8rem 1.2rem;
  border-radius: 0.8rem 0.8rem 0 0;
  background: #fff;
  border: 0.1rem solid #f5f5f5;
`

export const AddressBottom = styled.div`
  ${flexRow};
  justify-content: space-between;
  width: 32rem;
  height: 3.4rem;
  background: #f5f5f5;
  padding: 0.8rem 1.2rem;
  border-radius: 0 0 0.8rem 0.8rem;
`

export const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 2.2rem;
  color: #332a00;
  margin-bottom: 0.8rem;
`

export const InputAddress = styled.div<InputProps>`
  padding: 1.2rem;
  border: 0.1rem solid #ebebeb;
  border-radius: 0.8rem;
  height: ${props => (props.addStationWarning ? '12rem' : '10rem')};
`

type InputProps = {
  addStationWarning?: boolean
}
export const InputWrapper = styled.div<InputProps>`
  ${flexRow};
  border-radius: 0.8rem;
  border: ${props =>
    props.addStationWarning ? '0.1rem solid #ED766E' : '0.1rem solid #ebebeb'};
  background: ${props => (props.addStationWarning ? '#FCE6E4' : '#f5f5f5')};
  height: 3rem;
  padding: 0.6rem 0.8rem;
  column-gap: 0.4rem;
  margin-bottom: 0.8rem;

  :focus-within {
    background: #fffbe5;
    border: 0.1rem solid #ebebeb;
  }
`

export const AddStationWarning = styled.div`
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 300;
  color: #e74d41;
  margin-bottom: 0.8rem;
  padding-left: 0;
`

export const Input = styled.input<InputProps>`
  border: none;
  background: ${props => (props.addStationWarning ? '#FCE6E4' : '#f5f5f5')};
  color: ${props => (props.addStationWarning ? '#DA291C' : '#000')};
  height: 1.8rem;
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;

  :focus-within {
    background: #fffbe5;
  }
  ::placeholder {
    font-weight: 300;
  }
`

export const DeleteButtonWrapper = styled.div``

export const DeleteButton = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

export const RoadAddress = styled.div`
  font-size: 1.2rem;
  line-height: 1.9rem;
  font-weight: 400;
  color: #000;
`

export const Address = styled(RoadAddress)`
  color: #666666;
`

export const AcademyStatusWrapper = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
  height: 1.8rem;
  border-radius: 0 0 0.8rem 0.8rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: #999999;
`

export const ButtonWrapper = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
`

export const CancelButton = styled.button`
  border-radius: 1.6rem;
  border: none;
  background: #d9d9d9;
  padding: 0.2rem 0.8rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
`

export const AddButton = styled(CancelButton)`
  background: #ffd100;
  :disabled {
    background: #d9d9d9;
  }
`

export const AcademyStatusButton = styled.div``

export const SelectPlaceImg = styled(SVGImage)`
  width: 27px;
  height: 27px;
  background-color: transparent;
`

export const SelectStationInfo = styled.div`
  position: relative;
  width: 32rem;
  height: 9rem;
  border-radius: 0.8rem;
  background: #fffbe5;
  padding: 1.2rem;
  border: 0.1rem solid #ebebeb;
  ${flexColumn};
`

export const CloseImage = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 1.8rem;
  height: 1.8rem;
`

export const StationType = styled.div`
  color: #6100ff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 300;
`
export const AcademyIconImg = styled(IconImg)`
  width: 14px;
  height: 18px;
  padding-bottom: 3px;
  margin-right: 3px;
`


export const StationDivider = styled.div`
  border-top: 0.1rem solid #ebebeb;
  margin-top: 0.8rem;
`

export const StationTypeSelect = styled.div`
  width: 100%;
  display: grid;
  // 한 줄에 3개씩 보여주기
  grid-template-columns: repeat(3, 1fr);

  // 마지막 아이템이 아니면 오른쪽에 구분선 추가
  > div:not(:nth-child(3n)) {
    border-right: 0.1rem solid #ebebeb;
  }
`;

export const StationTypeSelectItem = styled.div<{ isSelected: boolean, isDisabled: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px;
  cursor: pointer;
  user-select: none;

  ${({ isDisabled }) => isDisabled && `
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #eaeaea;
  `}

  & > p {
    font-size: 12px;
    font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
    color: ${({ isSelected }) => (isSelected ? '#332A00' : '#a0a0a0')};
  }

  & > img {
    width: 12px;
    height: 12px;
    object-fit: cover;
  }
`;
