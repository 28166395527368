import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SVGImage from '../../common/SVGImage'
import { Button, color, flexColumn, flexRow } from '../../../style/CommonStyle'
import { Optional } from '../../../type/Common'

// image
import CloseIcon from '../../../asset/image/close_button.svg'
import { isEmptyString, isNil } from '../../../util/ValidationUtil'
import Select from '../../input/Select'
import { useRecoilValue } from 'recoil'
import { stationAtom, stationModifyAvailableAtom } from '../atom/station-management'

type ModalProps = {
  onClose: () => void
  putStationData: (modifyName: string, modifyType: string) => void
  error: Optional<string>
}

const academyOptions = [
  {
    idx: 0,
    exposure: '일반 정류장',
    value: 'NORMAL',
  },
  {
    idx: 1,
    exposure: '학원 정류장',
    value: 'ACADEMY',
  },
]

function ModifyStation(props: ModalProps) {
  const station = useRecoilValue(stationAtom);
  const stationModifyAvailable = useRecoilValue(stationModifyAvailableAtom);

  const [stationName, setStationName] = useState<string>('')
  const [academyType, setAcademyType] = useState<string>('')

  const onClick = () => {
    if (!stationName) return;
    props.putStationData(stationName, academyType);
  }

  useEffect(() => {
    if (!station) return;
    setStationName(station.name || '');
    setAcademyType(station.type || '');
  }, [station])

  return (
    <Container>
      <CloseButton onClick={props.onClose}>
        <CloseImg source={CloseIcon} />
      </CloseButton>
      <Header>정류장 수정</Header>
      <Noti>
        • <OrangeText>*</OrangeText> 는 필수 입력 항목입니다.
      </Noti>
      <NameWrapper>
        {!stationModifyAvailable ? (
          <OriginalInputWrapper>
            <SubTitle>정류장 유형</SubTitle>
            <OriginalValue>
              {station?.type === 'NORMAL' ? '일반 정류장' : '학원 정류장'}
            </OriginalValue>
          </OriginalInputWrapper>
        ) : (
          <ChangeInputWrapper
            error={props.error}
            style={{ background: '#FFFBE5' }}>
            <ChangeSubTitle>
              정류장 유형<OrangeText>*</OrangeText>
            </ChangeSubTitle>
            <Select
              options={academyOptions}
              onChange={(e) => setAcademyType(e as string)}
              width={19.6}
              height={2}
              border={false}
              boxShadow={false}
              defaultIdx={station?.type === 'NORMAL' ? 0 : 1}
              background="#fffbe5"
            />
          </ChangeInputWrapper>
        )}

        <OriginalInputWrapper>
          <SubTitle>기존 정류장</SubTitle>
          <OriginalValue>{station?.address}</OriginalValue>
        </OriginalInputWrapper>
        <OriginalInputWrapper>
          <SubTitle>기존 정류장명</SubTitle>
          <OriginalValue>{station?.name}</OriginalValue>
        </OriginalInputWrapper>

        <ChangeInputWrapper error={props.error}>
          <ChangeSubTitle>
            수정할 정류장명<OrangeText>*</OrangeText>
          </ChangeSubTitle>
          <Input
            value={stationName}
            onChange={e => setStationName(e.target.value)}
            placeholder="변경할 정류장명을 입력하세요."
          />
        </ChangeInputWrapper>

        {props.error?.includes('R101') && (
          <WarningText>이미 등록되어 있는 정류장명입니다.</WarningText>
        )}
      </NameWrapper>
      <ButtonWrapper>
        <CancelButton onClick={props.onClose}>취소</CancelButton>
        <RegisterButton
          disabled={stationName}
          onClick={onClick}>
          수정
        </RegisterButton>
      </ButtonWrapper>
    </Container>
  )
}

export default ModifyStation

const Container = styled.div`
  ${flexColumn};
  justify-content: center;
  text-align: center;
  border-radius: 1.6rem;
  position: relative;
`

const CloseButton = styled.div``

const CloseImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  top: 1.9rem;
  right: 2rem;
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1.6rem 0;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
`

const Noti = styled.div`
  height: 3rem;
  background: #ebebeb;
  padding: 0.8rem 1.6rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
  text-align: left;
  color: #999;
`

const OrangeText = styled.span`
  color: ${color.orange};
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
`

const NameWrapper = styled.div`
  ${flexColumn};
  background: #f5f5f5;
  padding: 2rem;
  row-gap: 1.2rem;
`

const OriginalInputWrapper = styled.div`
  ${flexRow};
  border-radius: 0.8rem;
  border: 0.1rem solid #ebebeb;
  padding: 0.5rem 1.2rem;
`

type ErrorProps = {
  error?: Optional<string>
}

const ChangeInputWrapper = styled(OriginalInputWrapper) <ErrorProps>`
  position: relative;
  border-color: ${props => (props.error === null ? '#d9d9d9' : '#ED766E')};
  background: ${props => (props.error === null ? '#fff' : '#FCE6E4')};

  > input {
    background: ${props => (props.error === null ? '#fff' : '#FCE6E4')};
    color: ${props => (props.error === null ? '#332A00' : '#DA291C')};
  }

  :focus-within {
    background: ${props => (props.error === null ? '#fffbe5' : '#FCE6E4')};
    border-color: ${props => (props.error === null ? '#ffe880' : '#ED766E')};
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);

    > input {
      background: ${props => (props.error === null ? '#fffbe5' : '#FCE6E4')};
      color: ${props => (props.error === null ? '#332A00' : '#DA291C')};
    }
  }
`

const Text = styled.div`
  color: #e74d41;
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 300;
  margin-top: 0.6rem;
`

const SubTitle = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
  color: #808080;
  width: 12.8rem;
  text-align: left;
  border-right: 0.1rem solid #d9d9d9;
  margin-right: 0.6rem;
`

const OriginalValue = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
  color: #808080;
`

const ChangeSubTitle = styled(SubTitle)`
  color: #332a00;
`

type InputProps = {
  addressName?: Optional<string>
}

const Input = styled.input<InputProps>`
  border: none;
  font-size: 1.3rem;
  font-weight: 500;
  width: 100%;
  max-width: 19.6rem;
  color: ${props => (!isNil(props.addressName) ? '#000' : '#ccc')};

  ::placeholder {
    color: #ccc;
  }

  :focus {
    background: #fffbe5;
  }

  :hover {
    cursor: pointer;
  }
`
const ButtonWrapper = styled.div`
  ${flexRow}
  column-gap: 0.6rem;
  justify-content: center;
  padding: 1.2rem;
`

const CancelButton = styled(Button)``

type ButtonProps = {
  disabled: Optional<string>
}

const RegisterButton = styled.div<ButtonProps>`
  height: 3.4rem;
  width: 7.2rem;
  border-radius: 3.4rem;
  padding: 0.8rem 1.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
  color: ${props => (isEmptyString(props.disabled) ? '#ccc' : '#000')};
  background: ${props =>
    isEmptyString(props.disabled) ? '#FFF1B3' : '#ffcd00'};
  cursor: ${props => (isEmptyString(props.disabled) ? 'default' : 'pointer')};
`

const WarningText = styled.div`
  color: #e74d41;
  font-size: 11px;
  font-weight: 300;
  line-height: 14px; /* 127.273% */
  letter-spacing: -0.33px;
  text-align: left;
  margin-top: -1rem;
`
