import { atom } from "recoil";
import { ToastType } from "../../../page/stationManagement/StationManagement";
import { CStation } from "../../../model/Station";
import { Optional } from "../../../type/Common";

export const STATION_MANAGEMENT_ATOM_KEYS = {
  'TOAST_ATOM_KEY': 'TOAST_ATOM_KEY',
  'MODAL_ATOM_KEY': 'MODAL_ATOM_KEY',
  'STATION_ATOM_KEY': 'STATION_ATOM_KEY',
  'STATION_MODIFY_AVAILABLE_ATOM_KEY': 'STATION_MODIFY_AVAILABLE_ATOM_KEY',
  'STATION_ADD_INFO_ATOM_KEY': 'STATION_ADD_INFO_ATOM_KEY',
}

export const toastAtom = atom<ToastType | null>({
  key: STATION_MANAGEMENT_ATOM_KEYS.TOAST_ATOM_KEY,
  default: null,
})


export const modalAtom = atom<string | null>({
key: STATION_MANAGEMENT_ATOM_KEYS.MODAL_ATOM_KEY,
  default: null,
})

export const stationAtom = atom<CStation | null | undefined>({
  key: STATION_MANAGEMENT_ATOM_KEYS.STATION_ATOM_KEY,
  default: null,
})

export const stationModifyAvailableAtom = atom<boolean>({
  key: STATION_MANAGEMENT_ATOM_KEYS.STATION_MODIFY_AVAILABLE_ATOM_KEY,
  default: false,
})

export const stationAddInfoAtom = atom<Optional<{
  station: CStation,
  type: string
}>>({
  key: STATION_MANAGEMENT_ATOM_KEYS.STATION_ADD_INFO_ATOM_KEY,
  default: null,
})